import React, { useContext } from "react";
import { Navbar, Nav } from "react-bootstrap";
import { Link } from "react-router-dom";
import {
  AiOutlineHome,
  AiOutlineLogout,
  AiOutlineSetting,
} from "react-icons/ai";
import Cookies from "js-cookie";
import { colors } from "../../../constants/colors";
import FormChooserDropdown from "./FormChooserDropdown";
import OrganizationContext from "../../../contexts/OrganizationContext";

export default function AdminPortalNavbar() {
  const { orgName } = useContext(OrganizationContext);

  function isSuperUser() {
    return Boolean(Cookies.get("su"));
  }

  return (
    <Navbar style={{ backgroundColor: colors.secondaryBlue }} className="px-5">
      <Navbar.Brand href="/app">
        <p className="mb-0">Canary</p>
      </Navbar.Brand>
      <div className="d-flex flex-row ml-3 mr-auto">
        <Nav>
          <Link
            to="/app"
            className="d-flex flex-row align-items-center text-black-50"
          >
            <AiOutlineHome />
          </Link>
        </Nav>
        <FormChooserDropdown />
      </div>

      <Nav>
        {isSuperUser() && (
          <Link
            to="/app/settings"
            className="d-flex flex-row align-items-center text-black-50 mr-3"
          >
            <AiOutlineSetting />
          </Link>
        )}
        <Link
          to="/app"
          className="d-flex flex-row align-items-center text-black-50 mr-3"
        >
          {orgName}
        </Link>
        <Link
          to="/app/logout"
          className="d-flex flex-row align-items-center text-black-50"
        >
          <AiOutlineLogout />
        </Link>
      </Nav>
    </Navbar>
  );
}
