import { InlineWidget } from "react-calendly";
import React from "react";
import "./BookCalendlyAppointment.scss";

export default function BookCalendlyAppointment({ calendlyLink }) {
  return (
    <div className="bookAppointmentContainer">
      <InlineWidget url={calendlyLink} />
    </div>
  );
}
