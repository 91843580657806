import React, { useEffect } from "react";
import { colors } from "../../../constants/colors";
import Container from "react-bootstrap/Container";
import Logo from "../../Logo";
import Spacer from "../../Spacer";
import FinePrint from "../FinePrint";
import useWindowWidth from "../../../hooks/useWindowWidth";
import "./LearnMore.scss";
import Pricing from "./Pricing";
import ExampleScenarios from "./ExampleScenarios";
import LearnMoreSection from "./LearnMoreSection";
import BackArrow from "../../BackArrow";

const initialConversationSection = {
  title: "Initial Conversation - What do you need?",
  body: (
    <div>
      <p>
        Canary offers a variety of ways to help employers respond to concerns
        from employees. When you have your initial consultation with us, we will
        figure out the right kind of solution for you.
      </p>
      <p>Here are some examples:</p>
    </div>
  ),
};

const implementationSection = {
  title: "Implementation - Making it Happen",
  body: (
    <div>
      <p>
        Once you invest in Canary, you want to make sure employees know about it
        and know how to use it.
      </p>
      <p>
        Canary offers everything from video tutorials to posters to live
        training for educating employees about Canary. Canary clients also
        receive reduced rates on WG Resolutions' workplace harassment, code of
        conduct and EDI training programs, which can include instructions on how
        to use Canary.
      </p>
    </div>
  ),
};

const supportRespondChangeSection = {
  title: "Support, Respond & Change - Keep doing the Work",
  body: (
    <div>
      <p>
        Canary is designed with the expertise of a law firm that focuses
        exclusively on handling workplace conflict. We can help you figure out
        how to respond to employees' concerns by directing you to the right
        people who do workplace culture audits, training and investigations.
      </p>
      <p>
        When things change, we change to suit you. Canary is all about creating
        ways to address employees' concerns as they arise and change over time.
        We can always re-customize your Canary product to ensure it captures the
        concerns that need to be heard.
      </p>
    </div>
  ),
};

export default function Index() {
  const { isMobile } = useWindowWidth();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div style={{ backgroundColor: colors.white }}>
      <div className="d-flex flex-column justify-content-center">
        <BackArrow to="/" />
        <Container style={{ maxWidth: 780 }}>
          <div className="hero">
            <Logo lg includeText className="mb-3 justify-content-center" />
            <h4 className="px-1">
              <p className="headerParagraph">
                Every employee and employer is different.
              </p>
              <p className="headerParagraph">
                That's why Canary is tailored to every client based on its
                workplace culture and the kinds of concerns that need to be
                heard.
              </p>
              <p className="headerParagraph">
                It all starts with the Initial Conversation.
              </p>
            </h4>
          </div>
          <Spacer height={100} />
          <LearnMoreSection content={initialConversationSection} />
          <ExampleScenarios />
          <Spacer height={40} />
          <LearnMoreSection content={implementationSection} />
          <Spacer height={40} />
          <LearnMoreSection content={supportRespondChangeSection} />
        </Container>
        <Spacer height={40} />
        <Pricing />
        <Spacer height={40} />
      </div>
      {!isMobile && <FinePrint />}
    </div>
  );
}
