import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import Cookies from "js-cookie";

export default function Logout() {
  const history = useHistory();

  useEffect(() => {
    if (Cookies.get("userId")) {
      fetch("/api/logout", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          history.push("/app/login");
        })
        .catch((err) => {
          history.push("/app/login");
        });
    } else {
      history.push("/app/login");
    }
  }, [history]);

  return <div></div>;
}
