import { useState } from "react";

const allDatesRange = {
  startDate: new Date("1960-01-01"),
  endDate: new Date("2100-01-01"),
};

export default function useDateRangeContext() {
  const [dateRange, setDateRange] = useState({
    startDate: allDatesRange.startDate,
    endDate: allDatesRange.endDate,
    key: "selection",
  });

  return {
    dateRange,
    setDateRange,
  };
}
