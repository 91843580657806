import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import LeadsFormForEmployers from "./LeadsFormForEmployers";
import Spacer from "../Spacer";
import BackArrow from "../BackArrow";
import { colors } from "../../constants/colors";
import Logo from "../Logo";

function InterestedEmployer() {
  return (
    <div style={{ backgroundColor: colors.white }}>
      <BackArrow to="/" />
      <Container className="d-flex vh-100 flex-column justify-content-center align-items-center">
        <Logo lg />
        <Row>
          <Col>
            Awesome! Please tell us about yourself and your company and we will
            get in touch:
          </Col>
        </Row>
        <Spacer height={50} />
        <Row>
          <LeadsFormForEmployers />
        </Row>
      </Container>
    </div>
  );
}

export default InterestedEmployer;
