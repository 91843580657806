import React, { useEffect, useState } from "react";
import Loader from "../../../../Loader";
import HorizontalBar from "../../../../charts/HorizontalBar";
import { covid19Colors } from "../../../../../constants/colors";
import ScalarScore from "../../../../charts/ScalarScore";
import NoResponses from "./NoResponses";
import Doughnut from "../../../../charts/Doughnut";

const screeningTypeLabels = [
  "Employees",
  "Visitors (including clients and contractors)",
  "Other",
];

const screeningIssueLabels = [
  "Screening is not being done",
  "Screening is not done consistently",
  "People are dishonest when answering screening questions",
  "There isn't enough training on how/when to do screening",
  "The screening protocols are not safe enough",
  "Other",
];

export default function ScreeningTab({
  dateRange,
  projectsFilter,
  updateRespondentCounter,
}) {
  const [results, setResults] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function requestInitialData() {
      setLoading(true);
      const res = await fetch(
        `/api/covid19BuildingUp/calculateScreening?startDate=${dateRange.startDate}&endDate=${dateRange.endDate}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ projectsFilter }),
        }
      );
      const results = await res.json();
      setResults(results);
      updateRespondentCounter(results.total);
      setLoading(false);
    }
    requestInitialData();
  }, [
    updateRespondentCounter,
    dateRange.startDate,
    dateRange.endDate,
    projectsFilter,
  ]);

  if (loading || !results) return <Loader />;

  if (results.total === 0) {
    return (
      <NoResponses
        text="No Screening concerns have been reported."
        height={400}
      />
    );
  }

  const screeningTypeData = screeningTypeLabels.map(
    (label) => results.screeningType[label]
  );
  const screeningIssueData = screeningIssueLabels.map(
    (label) => results.screeningIssue[label]
  );

  return (
    <div>
      <HorizontalBar
        data={screeningTypeData}
        labels={screeningTypeLabels}
        title="Are these concerns about the screening of..."
        maxValue={results.total}
        palette={covid19Colors.screening}
      />
      <HorizontalBar
        data={screeningIssueData}
        labels={screeningIssueLabels}
        title="What is the Screening issue?"
        maxValue={results.total}
        palette={covid19Colors.screening}
      />
      <div className="d-flex flex-row">
        <Doughnut
          data={results.frequency}
          title="Frequency"
          tooltip="How frequently has this occurred?"
        />
        <ScalarScore
          score={results.intensity}
          title="Intensity"
          tooltip="How serious are these safety concerns, from your perspective? (1 to 10)"
        />
        <Doughnut
          data={results.timePeriod}
          title="Time Period"
          tooltip="This occurred during the last..."
        />
        <Doughnut
          data={results.repeat}
          title="Repeat"
          tooltip="Is this the first time you are reporting this issue?"
        />
      </div>
    </div>
  );
}
