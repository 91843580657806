import React from "react";
import { Route, Switch } from "react-router-dom";
import PrivateRoute from "../../PrivateRoute";
import DashboardSelector from "./reports/DashboardSelector";
import Settings from "./superUser/Settings";
import OrganizationPage from "./superUser/OrganizationPage";
import NotFound from "../NotFound";
import AdminPortalNavbar from "./AdminPortalNavbar";
import OrganizationContext from "../../../contexts/OrganizationContext";
import DateRangeContext from "../../../contexts/DateRangeContext";
import { useOrganizationContextData } from "../../../hooks/useOrganizationContextData";
import useDateRangeContext from "../../../hooks/useDateRangeContext";

export default function AdminPanel() {
  const organizationContextData = useOrganizationContextData();
  const dateRangeContextData = useDateRangeContext();
  return (
    <div>
      <OrganizationContext.Provider value={organizationContextData}>
        <AdminPortalNavbar />
        <div className="pt-5">
          <Switch>
            <PrivateRoute
              type="admin"
              path="/app/settings"
              component={Settings}
            />
            <PrivateRoute
              type="admin"
              path="/app/org/:orgId"
              component={OrganizationPage}
            />
            <DateRangeContext.Provider value={dateRangeContextData}>
              <PrivateRoute
                type="admin"
                exact
                path="/app"
                component={DashboardSelector}
              />
            </DateRangeContext.Provider>
            <Route component={NotFound} />
          </Switch>
        </div>
      </OrganizationContext.Provider>
    </div>
  );
}
