import React, { useEffect, useState } from "react";
import Loader from "../../../../Loader";
import HorizontalBar from "../../../../charts/HorizontalBar";
import { covid19Colors } from "../../../../../constants/colors";
import ScalarScore from "../../../../charts/ScalarScore";
import NoResponses from "./NoResponses";
import Doughnut from "../../../../charts/Doughnut";

const typeLabels = [
  "Employees",
  "Visitors (including clients and contractors)",
  "Other",
];

const issueLabels = [
  "They are not self-isolating when they should be",
  "They are not monitoring symptoms",
  "People are dishonest about their self-isolation and monitoring",
  "There isn't enough training on how/when to do it",
  "There aren't enough employees to allow for self-isolation",
  "The monitoring and self-isolation protocols are not safe enough",
  "Other",
];

export default function MonitoringTab({
  dateRange,
  projectsFilter,
  updateRespondentCounter,
}) {
  const [results, setResults] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function requestInitialData() {
      setLoading(true);
      const res = await fetch(
        `/api/covid19BuildingUp/calculateMonitoring?startDate=${dateRange.startDate}&endDate=${dateRange.endDate}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ projectsFilter }),
        }
      );
      const results = await res.json();
      setResults(results);
      updateRespondentCounter(results.total);
      setLoading(false);
    }
    requestInitialData();
  }, [
    updateRespondentCounter,
    dateRange.startDate,
    dateRange.endDate,
    projectsFilter,
  ]);

  if (loading || !results) return <Loader />;

  if (results.total === 0) {
    return (
      <NoResponses
        text="No Monitoring concerns have been reported."
        height={400}
      />
    );
  }

  const typeData = typeLabels.map((label) => results.monitoringType[label]);
  const issueData = issueLabels.map((label) => results.monitoringIssue[label]);

  return (
    <div>
      <HorizontalBar
        data={typeData}
        labels={typeLabels}
        title="Are these self-isolating/monitoring concerns about..."
        maxValue={results.total}
        palette={covid19Colors.monitoring}
      />
      <HorizontalBar
        data={issueData}
        labels={issueLabels}
        title="What is the self-isolating/monitoring issue?"
        maxValue={results.total}
        palette={covid19Colors.monitoring}
      />
      <div className="d-flex flex-row">
        <Doughnut
          data={results.frequency}
          title="Frequency"
          tooltip="How frequently has this occurred?"
        />
        <ScalarScore
          score={results.intensity}
          title="Intensity"
          tooltip="How serious are these safety concerns, from your perspective? (1 to 10)"
        />
        <Doughnut
          data={results.timePeriod}
          title="Time Period"
          tooltip="This occurred during the last..."
        />
        <Doughnut
          data={results.repeat}
          title="Repeat"
          tooltip="Is this the first time you are reporting this issue?"
        />
      </div>
    </div>
  );
}
