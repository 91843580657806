import React from "react";
import { Route, Redirect, useLocation } from "react-router-dom";
import Cookies from "js-cookie";

export default function PrivateRoute({ type, ...props }) {
  let location = useLocation();

  if (userLoggedIn(type)) {
    return <Route {...props} />;
  } else {
    const redirectPath = type === "admin" ? "/app/login" : "/anonymousReport";
    return (
      <Redirect to={{ pathname: redirectPath, state: { from: location } }} />
    );
  }
}

function userLoggedIn(type) {
  if (type === "organization") {
    return true;
    // TODO: return Boolean(Cookies.get("orgId"));
  } else if (type === "admin") {
    return Boolean(Cookies.get("userId"));
  } else {
    return false;
  }
}
