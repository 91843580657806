import React from "react";
import Doughnut2, {
  SIMILAR_LABEL_MARKER,
} from "../../../../../charts/Doughnut2";
import { forms } from "line3_shared/constants/forms";

export default function BottomDoughnuts({
  filter,
  includeCommentCharts = false,
}) {
  return (
    <div className="d-flex flex-row justify-content-end mt-5">
      <Doughnut2
        title="Time Period"
        tooltip="This occurred during the last..."
        query={{
          formId: forms.concerns,
          questionId: "AJvKpu2AAQRy",
          filter,
        }}
      />
      <Doughnut2
        title="Frequency"
        tooltip="How frequently has this occurred?"
        query={{
          formId: forms.concerns,
          questionId: "hxevrGkgspp5",
          filter,
        }}
      />
      <Doughnut2
        title="Repeat"
        tooltip="Have you made a Canary report about this same concern before?"
        query={{
          formId: forms.concerns,
          buckets: [
            {
              label: "Yes",
              match: {
                and: [
                  { questionId: "XxuNrefTp0e0", answerId: false },
                  { questionId: "FmUPlnVnIh7h", answerId: "eYrmNYiusdnu" },
                ],
              },
            },
            {
              label: "No",
              match: {
                or: [{ questionId: "XxuNrefTp0e0", answerId: true }],
              },
            },
            {
              label: "No" + SIMILAR_LABEL_MARKER, // This marker means that this bucket will be aggregated with the "No" bucket above. This is a temporary solution, because our query language currently doesn't support an AND nested within an OR
              match: {
                and: [
                  { questionId: "XxuNrefTp0e0", answerId: false },
                  { questionId: "FmUPlnVnIh7h", answerId: "dhgR56g2P0Pa" },
                ],
              },
            },
          ],
          filter,
        }}
      />
      {includeCommentCharts && (
        <>
          <Doughnut2
            title="Public/Private"
            tooltip="Were the comments made in public or private?"
            query={{
              formId: forms.concerns,
              buckets: [
                {
                  label: "Public",
                  match: {
                    questionId: "3gSrJHiYU6BB",
                    answerId: "RseitdAvRFtE",
                  },
                },
                {
                  label: "Private",
                  match: {
                    questionId: "3gSrJHiYU6BB",
                    answerId: "GYvREM2rRJiP",
                  },
                },
              ],
              filter,
            }}
          />
          <Doughnut2
            title="Volume/Tone Issue"
            tooltip="Did the comments have a volume or tone issue?"
            query={{
              formId: forms.concerns,
              buckets: [
                {
                  label: "Yes",
                  match: {
                    or: [
                      { questionId: "bfGttwfge50C", answerId: "ve7v78bBXhxI" },
                      { questionId: "bfGttwfge50C", answerId: "Jx33WqeHpGBF" },
                      { questionId: "bfGttwfge50C", answerId: "7ckJykVao3dd" },
                      { questionId: "bfGttwfge50C", answerId: "b81tGc9Yxv6S" },
                      { questionId: "bfGttwfge50C", answerId: "crCpxvP4Ngjv" },
                      { questionId: "bfGttwfge50C", answerId: "d3ePwXF75JIO" },
                      { questionId: "bfGttwfge50C", answerId: "iSlD6cdVfVa1" },
                      { questionId: "bfGttwfge50C", answerId: "0Z5PFRvchLfx" },
                      { questionId: "bfGttwfge50C", answerId: "FB7jt74HfkH2" },
                      { questionId: "bfGttwfge50C", answerId: "Otion7QAEX6n" },
                      { questionId: "bfGttwfge50C", answerId: "1Jt42Uvav34L" },
                      { questionId: "bfGttwfge50C", answerId: "y0gsWIcgAeve" },
                    ],
                  },
                },
                {
                  label: "No",
                  match: {
                    questionId: "bfGttwfge50C",
                    answerId: "iJRwIAVA95bH",
                  },
                },
              ],
              filter,
            }}
          />
        </>
      )}
    </div>
  );
}
