import {useEffect, useState} from "react";

export default function useWindowWidth() {
  const [windowWidth, setWindowWidth] = useState(0);
  const [windowHeight, setWindowHeight] = useState(0);

  useEffect(() => {
    updateDimensions();
    window.addEventListener("resize", updateDimensions);
    return () => {
      window.removeEventListener("resize", updateDimensions);
    };
  });

  function updateDimensions() {
    let windowWidth = typeof window !== "undefined" ? window.innerWidth : 0;
    let windowHeight = typeof window !== "undefined" ? window.innerHeight : 0;
    setWindowWidth(windowWidth);
    setWindowHeight(windowHeight);
  }

  return { windowWidth, windowHeight, isMobile: windowWidth < 500 }
}
