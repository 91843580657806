import React from "react";
import { Tab } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import Container from "react-bootstrap/Container";
import Organizations from "./Organizations";
import AddOrganization from "./AddOrganization";
import AddUser from "./AddUser";
import ViewLeads from "./ViewLeads";
import TabContainer from "../../../TabContainer";

export default function Settings() {
  return (
    <Container>
      <TabContainer defaultActiveKey="organizations">
        <Row>
          <Col sm={3}>
            <Nav className="flex-column">
              <Nav.Item>
                <Nav.Link eventKey="organizations">Organizations</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="addOrganization">Add Organization</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="addUser">Add User</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="viewLeads">View Leads</Nav.Link>
              </Nav.Item>
            </Nav>
          </Col>
          <Col sm={9} md={8}>
            <Tab.Content>
              <Tab.Pane eventKey="organizations">
                <Organizations />
              </Tab.Pane>
              <Tab.Pane eventKey="addOrganization">
                <AddOrganization />
              </Tab.Pane>
              <Tab.Pane eventKey="addUser">
                <AddUser />
              </Tab.Pane>
              <Tab.Pane eventKey="viewLeads">
                <ViewLeads />
              </Tab.Pane>
            </Tab.Content>
          </Col>
        </Row>
      </TabContainer>
    </Container>
  );
}
