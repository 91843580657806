import { createContext } from "react";

const allDatesRange = {
  startDate: new Date("1960-01-01"),
  endDate: new Date("2100-01-01"),
};

export default createContext({
  dateRange: {
    startDate: allDatesRange.startDate,
    endDate: allDatesRange.endDate,
    key: "selection",
  },
  setDateRange: null,
});
