import React from "react";
import { covid19Colors } from "../../constants/colors";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { Doughnut as ChartJsDoughnut } from "react-chartjs-2";
import useGetDataFromQuery from "../../hooks/useGetDataFromQuery";
import { QueryType } from "line3_shared/types/QueryType";
import Loader from "../Loader";

type PropTypes = {
  query: QueryType;
  title?: string;
  tooltip?: string;
  options?: any;
};

export default function Doughnut({
  query,
  title,
  tooltip,
  options,
}: PropTypes) {
  const { data, loading } = useGetDataFromQuery(query);

  if (loading || !data) return <Loader />;

  const processedData = combineSimilarLabels(data);

  const labels = processedData && processedData.labels.map((label) => label.label);
  const values = processedData && processedData.labels.map((label) => label.count);

  const chartData = {
    datasets: [
      {
        data: values,
        backgroundColor: Object.values(covid19Colors),
      },
    ],
    labels,
  };

  const chartOptions = {
    legend: {
      display: false,
    },
    ...options,
  };

  function renderTooltip(props) {
    return (
      <Tooltip id="scalar-tooltip" {...props}>
        {tooltip}
      </Tooltip>
    );
  }

  return (
    <div className="d-flex flex-column align-items-center mx-2">
      <OverlayTrigger
        placement="right"
        delay={{ show: 200, hide: 0 }}
        overlay={renderTooltip}
      >
        <div style={{ height: 60, width: 120 }}>
          <ChartJsDoughnut data={chartData} options={chartOptions} />
        </div>
      </OverlayTrigger>
      <p>{title}</p>
    </div>
  );
}

export const SIMILAR_LABEL_MARKER = "#!SIMILAR#!";

/*
The function below allows us to aggregate the count for two similar labels on the client side.
It is to accommodate the revised logic for the Repeat doughnut chart.
It's a temporary client-side solution, because our query language on the server-side currently doesn't support
certain complex queries (in this case, an AND nested within an OR).
Eventually, we should amend the query language capabilities on the server-side to the support this.
 */
const combineSimilarLabels = (data) => {
  const { labels } = data

  if (labels.length > 0 && labels[0].label) {
    const newLabelCountMap = {}

    labels.forEach(labelObject => {
      let { label, count } = labelObject

      label = label.replace(SIMILAR_LABEL_MARKER, '')

      if (!newLabelCountMap[label]) {
        newLabelCountMap[label] = count
      } else {
        newLabelCountMap[label] = newLabelCountMap[label] + count
      }
    })

    const newLabelObjects = []

    for (let label in newLabelCountMap) {
      newLabelObjects.push({ label: label, count: newLabelCountMap[label] })
    }

    return { labels: newLabelObjects}
  } else {
    return data
  }
}
