import React, { useEffect } from "react";
import HorizontalBar2 from "../../../../charts/HorizontalBar2";
import IdentityTargetedChart, {
  IDENTITY_BUCKETS_TYPE,
} from "./charts/IdentityTargetedChart";
import IncidentTargetChart from "./charts/IncidentTargetChart";
import IncidentTransgressorChart from "./charts/IncidentTransgressorChart";
import BottomDoughnuts from "./charts/BottomDoughnuts";
import { forms } from "line3_shared/constants/forms";
import { concernsColorPalette } from "../../../../../constants/colors";
import Loader from "../../../../Loader";
import useGetDataFromQuery from "../../../../../hooks/useGetDataFromQuery";

export default function OverviewTab({ dateRange, updateRespondentCounter }) {
  const { loading, data } = useGetDataFromQuery({
    formId: forms.concerns,
    questionId: "hxevrGkgspp5",
  });

  useEffect(() => {
    if (data) {
      updateRespondentCounter(data.total);
    }
  }, [updateRespondentCounter, dateRange, data]);

  if (loading) return <Loader />;

  const behavioursReported = {
    formId: forms.concerns,
    buckets: [
      {
        label: "Gestures / Body Language",
        match: { questionId: "lXcP5TKUJy1j", answerId: true },
      },
      {
        label: "Sexual Gestures or Actions",
        match: { questionId: "ijNYixvWCRDN", answerId: true },
      },
      {
        label: "Physical Contact",
        match: { questionId: "vlipHxg2Vhal", answerId: true },
      },
      {
        label: "Exclusion or Favouritism",
        match: {
          or: [
            { questionId: "6Scklup520Lb", answerId: true }, // Avoiding or excluding certain people
            { questionId: "0aiSGGNFJ9mA", answerId: true }, // Attention-seeking behaviour
            { questionId: "T4TywmqAb8e8", answerId: true }, // Favoritism for certain people
          ],
        },
      },
      {
        label: "Work Management Issues",
        match: { questionId: "h9aCykuWSqZG", answerId: true },
      },
      {
        label: "General Comments",
        match: {
          or: [
            { questionId: "spNn7xtWmtbz", answerId: "R5Gmf2oU2sZk" },
            { questionId: "spNn7xtWmtbz", answerId: "DbhUolHzFjP2" },
            { questionId: "spNn7xtWmtbz", answerId: "MzkQToSTY6rj" },
            { questionId: "spNn7xtWmtbz", answerId: "MB6yadcMi4mn" },
            { questionId: "spNn7xtWmtbz", answerId: "Z2K9rotOmQEc" },
            { questionId: "spNn7xtWmtbz", answerId: "bL747UzeVpRk" },
          ],
        },
      },
      {
        label: "Sexual Comments",
        match: { questionId: "2mi2KfWz8jLP", answerId: true },
      },
      {
        label: "Work Comments",
        match: { questionId: "AtEHLMu652zg", answerId: true },
      },
      {
        label: "Policy Violations",
        match: { questionId: "8OLwDv9zcTpH", answerId: "B6rBvv1axgLK" },
      },
    ],
  };

  return (
    <div>
      <HorizontalBar2
        title="Behaviours Reported"
        palette={concernsColorPalette}
        query={behavioursReported}
      />
      <IdentityTargetedChart
        identityBucketsType={IDENTITY_BUCKETS_TYPE.BOTH_COMMENTS_AND_GENERAL}
      />
      <IncidentTargetChart />
      <IncidentTransgressorChart />
      <BottomDoughnuts />
    </div>
  );
}
