import React, { useEffect, useState } from "react";
import HorizontalBar from "../../../../charts/HorizontalBar";
import Loader from "../../../../Loader";
import { covid19Colors } from "../../../../../constants/colors";
import ScalarScore from "../../../../charts/ScalarScore";
import NoResponses from "./NoResponses";
import Doughnut from "../../../../charts/Doughnut";

const labels = [
  "PPE",
  "Screening",
  "Monitoring",
  "Personal Hygiene",
  "Cleaning and Sanitation",
  "Social Distancing",
  "Other",
];

export default function OverviewTab({
  dateRange,
  projectsFilter,
  updateRespondentCounter,
}) {
  const [results, setResults] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function requestInitialData() {
      setLoading(true);
      const res = await fetch(
        `/api/covid19BuildingUp/calculateOverall?startDate=${dateRange.startDate}&endDate=${dateRange.endDate}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ projectsFilter }),
        }
      );
      const results = await res.json();
      setResults(results);
      updateRespondentCounter(results.total);
      setLoading(false);
    }
    requestInitialData();
  }, [updateRespondentCounter, dateRange, projectsFilter]);

  if (loading) return <Loader />;

  if (results.total === 0) {
    return (
      <NoResponses text="No reports have been submitted yet." height={400} />
    );
  }

  const data = [
    results.ppe,
    results.screening,
    results.monitoring,
    results.personalHygiene,
    results.cleaningAndSanitation,
    results.socialDistancing,
    results.other,
  ];

  const palette = [
    covid19Colors.ppe,
    covid19Colors.screening,
    covid19Colors.monitoring,
    covid19Colors.personalHygiene,
    covid19Colors.cleaningAndSanitation,
    covid19Colors.socialDistancing,
    covid19Colors.other,
  ];

  return (
    <div>
      <HorizontalBar
        data={data}
        title="Respondents concerned with the following issues"
        maxValue={results.total}
        labels={labels}
        palette={palette}
      />
      <div className="d-flex flex-row justify-content-end mt-5">
        <Doughnut
          data={results.frequency}
          title="Frequency"
          tooltip="How frequently has this occurred?"
        />
        <ScalarScore
          score={results.intensity}
          title="Intensity"
          tooltip="How serious are these safety concerns, from your perspective? (1 to 10)"
        />
        <Doughnut
          data={results.timePeriod}
          title="Time Period"
          tooltip="This occurred during the last..."
        />
        <Doughnut
          data={results.repeat}
          title="Repeat"
          tooltip="Is this the first time you are reporting this issue?"
        />
      </div>
    </div>
  );
}
