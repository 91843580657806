import React, { useEffect, useState } from "react";
import Loader from "../../../../Loader";
import HorizontalBar from "../../../../charts/HorizontalBar";
import { covid19Colors } from "../../../../../constants/colors";
import ScalarScore from "../../../../charts/ScalarScore";
import NoResponses from "./NoResponses";
import Doughnut from "../../../../charts/Doughnut";

const ppeEquipmentLabels = [
  "Masks/N95s",
  "Face shields or Eye goggles",
  "Gloves",
  "Clothing (Gowns)",
  "Other",
];
const ppeIssueLabels = [
  "It's not available",
  "It's not accessible when it's needed",
  "There isn't enough",
  "It's not the right type of PPE",
  "It doesn't fit",
  "It doesn't work",
  "It's not safely disposed",
  "There isn't enough training on how/when to use it",
  "The PPE protocols are not safe enough",
  "Other",
];

export default function PpeTab({
  dateRange,
  projectsFilter,
  updateRespondentCounter,
}) {
  const [results, setResults] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function requestInitialData() {
      setLoading(true);
      const res = await fetch(
        `/api/covid19BuildingUp/calculatePpe?startDate=${dateRange.startDate}&endDate=${dateRange.endDate}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ projectsFilter }),
        }
      );
      const results = await res.json();
      setResults(results);
      updateRespondentCounter(results.total);
      setLoading(false);
    }
    requestInitialData();
  }, [updateRespondentCounter, dateRange, projectsFilter]);

  if (loading || !results) return <Loader />;

  if (results.total === 0) {
    return (
      <NoResponses text="No PPE concerns have been reported." height={400} />
    );
  }

  const ppeEquipmentData = ppeEquipmentLabels.map(
    (label) => results.ppeEquipment[label]
  );
  const ppeIssueData = ppeIssueLabels.map((label) => results.ppeIssue[label]);

  return (
    <div>
      <HorizontalBar
        data={ppeEquipmentData}
        labels={ppeEquipmentLabels}
        title="What type of PPE does your concern relate to?"
        maxValue={results.total}
        palette={covid19Colors.ppe}
      />
      <HorizontalBar
        data={ppeIssueData}
        labels={ppeIssueLabels}
        title="What is the PPE issue?"
        maxValue={results.total}
        palette={covid19Colors.ppe}
      />
      <div className="d-flex flex-row">
        <Doughnut
          data={results.frequency}
          title="Frequency"
          tooltip="How frequently has this occurred?"
        />
        <ScalarScore
          score={results.intensity}
          title="Intensity"
          tooltip="How serious are these safety concerns, from your perspective? (1 to 10)"
        />
        <Doughnut
          data={results.timePeriod}
          title="Time Period"
          tooltip="This occurred during the last..."
        />
        <Doughnut
          data={results.repeat}
          title="Repeat"
          tooltip="Is this the first time you are reporting this issue?"
        />
      </div>
    </div>
  );
}
