import React, { useEffect } from "react";
import HorizontalBar2 from "../../../../charts/HorizontalBar2";
import { concernsColorPalette } from "../../../../../constants/colors";
import IdentityTargetedChart, {
  IDENTITY_BUCKETS_TYPE,
} from "./charts/IdentityTargetedChart";
import IncidentTargetChart from "./charts/IncidentTargetChart";
import IncidentTransgressorChart from "./charts/IncidentTransgressorChart";
import BottomDoughnuts from "./charts/BottomDoughnuts";
import useGetDataFromQuery from "../../../../../hooks/useGetDataFromQuery";
import { forms } from "line3_shared/constants/forms";
import Loader from "../../../../Loader";
import ScalarScore2 from "../../../../charts/ScalarScore2";

export default function PolicyViolationsTab({
  dateRange,
  updateRespondentCounter,
}) {
  const policyFilter = {
    or: [
      {
        questionId: "8OLwDv9zcTpH",
        answerId: "B6rBvv1axgLK",
      },
    ],
  };

  const { loading, data } = useGetDataFromQuery({
    formId: forms.concerns,
    questionId: "hxevrGkgspp5",
    filter: policyFilter,
  });

  useEffect(() => {
    if (data) {
      updateRespondentCounter(data.total);
    }
  }, [updateRespondentCounter, dateRange, data]);

  if (loading) return <Loader />;
  return (
    <div>
      <HorizontalBar2
        title="What policy was violated?"
        palette={concernsColorPalette}
        query={{
          formId: forms.concerns,
          questionId: "Kdm4gNYTFtrS",
          filter: policyFilter,
        }}
      />
      <ScalarScore2
        title="Significance"
        tooltip="How significant are the consequences of this policy violation to your employer, from your perspective?"
        query={{
          formId: forms.concerns,
          questionId: "ZFdhwj0lfKgE",
        }}
      />
      <HorizontalBar2
        title="Are there financial costs or losses as a result of this policy violation?"
        palette={concernsColorPalette}
        query={{
          formId: forms.concerns,
          questionId: "L6sgRB4Fw4fl",
          filter: policyFilter,
        }}
      />
      <HorizontalBar2
        title="If you could guess, what is the approximate financial cost of the policy violation in dollars?"
        palette={concernsColorPalette}
        query={{
          formId: forms.concerns,
          questionId: "uYODnvGiGxua",
          filter: policyFilter,
        }}
      />
      <IdentityTargetedChart
        filter={policyFilter}
        identityBucketsType={IDENTITY_BUCKETS_TYPE.GENERAL}
      />
      <IncidentTargetChart filter={policyFilter} />
      <IncidentTransgressorChart filter={policyFilter} />
      <BottomDoughnuts filter={policyFilter} />
    </div>
  );
}
