import React, { useEffect } from "react";
import { concernsColorPalette } from "../../../../../constants/colors";
import HorizontalBar2 from "../../../../charts/HorizontalBar2";
import { forms } from "line3_shared/constants/forms";
import useGetDataFromQuery from "../../../../../hooks/useGetDataFromQuery";
import Loader from "../../../../Loader";
import Spacer from "../../../../Spacer";

export default function InsightsTab({ dateRange, updateRespondentCounter }) {
  const { loading, data } = useGetDataFromQuery({
    formId: forms.concerns,
    questionId: "hxevrGkgspp5",
  });

  useEffect(() => {
    if (data) {
      updateRespondentCounter(data.total);
    }
  }, [updateRespondentCounter, dateRange, data]);

  if (loading) return <Loader />;

  return (
    <div>
      <HorizontalBar2
        title="Consequences"
        palette={concernsColorPalette}
        query={{
          formId: forms.concerns,
          questionId: "aobAk9yNJ3vH",
        }}
      />
      <HorizontalBar2
        title="Roadblocks to reporting to HR or a manager"
        palette={concernsColorPalette}
        query={{
          formId: forms.concerns,
          questionId: "8LE4LmeldHen",
        }}
      />
      <HorizontalBar2
        title="Possible causes"
        palette={concernsColorPalette}
        query={{
          formId: forms.concerns,
          questionId: "zOOMsLAGVtnF",
        }}
      />
      <HorizontalBar2
        title="How this should be resolved"
        palette={concernsColorPalette}
        query={{
          formId: forms.concerns,
          questionId: "fx088f61RDpA",
        }}
      />
      <Spacer height={40} />
    </div>
  );
}
