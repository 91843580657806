import { useContext, useEffect, useState } from "react";
import { QueryType } from "line3_shared/types/QueryType";
import { DataType } from "line3_shared/types/DataType";
import DateRangeContext from "../contexts/DateRangeContext";

export default function useGetDataFromQuery(
  query: QueryType
): {
  data: DataType | null;
  responseType: string | null;
  loading: boolean;
} {
  const [data, setData] = useState<DataType | null>(null);
  const [responseType, setResponseType] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const { dateRange } = useContext(DateRangeContext);

  useEffect(() => {
    async function requestData() {
      setLoading(true);
      const data = {
        query,
        dateRange,
      };
      const res = await fetch(`/api/calculate`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data),
      });
      const results = await res.json();
      setData(results.data);
      setResponseType(results.responseType);
      setLoading(false);
    }
    requestData();
  }, [dateRange]); // eslint-disable-line react-hooks/exhaustive-deps

  return { data, responseType, loading };
}
