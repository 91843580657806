import { forms } from "line3_shared/constants/forms";
import React, { useContext, useEffect, useState } from "react";
import { RawAnswersQueryType } from "../../../../../../../line3_shared/types/RawAnswersQueryType";
import DateRangeContext from "../../../../../contexts/DateRangeContext";
import convertJSDateStringToISODateFormat from "../../../../../helpers/convertJSDateStringToISODateFormat";
import sortFunctionTreatingArraysAsStrings from "../../../../../helpers/sortFunctionTreatingArraysAsStrings";
import Loader from "../../../../Loader";
import "./Table.css";
import DataTable from "react-data-table-component";

export default function ReportsTab({ updateRespondentCounter }) {
  const [loading, setLoading] = useState(false);
  const [answers, setAnswers] = useState([]);
  const { dateRange } = useContext(DateRangeContext);

  const query: RawAnswersQueryType = {
    formId: forms.shortenedForm,
    questions: [
      {
        key: "writtenStatement",
        questionId: "OUJYWFU6MNfE",
      },
      {
        key: "suggestedSolution",
        questionId: "Wc2h5qJxk4Q8",
      },
      {
        key: "reasonsForRemainingAnonymous",
        questionId: "Mr0HvqTgFub9",
      },
      {
        key: "email",
        questionId: "20GSftDVFWMx",
      },
      {
        key: "recurence",
        questionId: "PpHsLIrUFKq3",
      },
      {
        key: "occurredAt_1", // This question is an OR with the one below, parsed on the frontend (easier that way)
        questionId: "uEzitB850VwR",
      },
      {
        key: "occurredAt_2",
        questionId: "5934BsDIK8IO",
      },
      {
        key: "reportedToManagementAt_1", // This question is an OR with the one below, parsed on the frontend (easier that way)
        questionId: "DWkpb35Fo43S",
      },
      {
        key: "reportedToManagementAt_2",
        questionId: "ilEmcqYOJOhZ",
      },
      {
        key: "transgressor",
        questionId: "7kKrhvt40hRq",
      },
      {
        key: "issue",
        questionId: "81iLlOKLMEr2",
      },
    ],
  };
  useEffect(() => {
    async function requestData() {
      setLoading(true);
      const body = {
        dateRange,
        query,
      };
      const res = await fetch(`/api/answers`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(body),
      });
      const results = await res.json();
      const finalAnswers = results.map((answer) => ({
        ...answer,
        submittedAt: convertJSDateStringToISODateFormat(answer.submittedAt),
        occurredAt: convertJSDateStringToISODateFormat(answer.occurredAt_1 || answer.occurredAt_2),
        reportedToManagementAt:
          convertJSDateStringToISODateFormat(answer.reportedToManagementAt_1 || answer.reportedToManagementAt_2),
      }));
      setAnswers(finalAnswers);
      updateRespondentCounter(results.length);
      setLoading(false);
    }
    requestData();
  }, [dateRange]); // eslint-disable-line react-hooks/exhaustive-deps

  const columns = React.useMemo(
    () => [
      {
        name: "Date Submitted",
        selector: (row) => row.submittedAt,
        sortable: true,
        wrap: true,
        minWidth: '150px',
      },
      {
        name: "Written Statement",
        selector: (row) => <div style={{ marginTop: 10, marginBottom: 10 }}>{row.writtenStatement}</div>,
        sortable: false,
        wrap: true,
        minWidth: '250px',
      },
      {
        name: "Suggested Solution",
        selector: (row) => row.suggestedSolution,
        sortable: false,
        wrap: true,
        minWidth: '200px',
      },
      {
        name: "Reasons for Remaining Anonymous",
        selector: (row) => row.reasonsForRemainingAnonymous,
        sortable: false,
        wrap: true,
        minWidth: '250px',
      },
      {
        name: "Email Address",
        selector: (row) => row.email,
        sortable: true,
        wrap: true,
        minWidth: '200px',
      },
      {
        name: "One-time or Ongoing",
        selector: (row) => row.recurence,
        sortable: true,
        wrap: true,
        minWidth: '200px',
      },
      {
        name: "Date of Occurrence",
        selector: (row) => row.occurredAt  || '',
        sortable: true,
        wrap: true,
        minWidth: '180px',
      },
      {
        name: "Date Reported to Management",
        selector: (row) => row.reportedToManagementAt || '',
        sortable: true,
        wrap: true,
        minWidth: '230px',
      },
      {
        name: "Transgressor",
        selector: (row) => <div style={{ marginTop: 10 }}>{row.transgressor.map(x => <p>{x}<br/></p>)}</div>,
        sortable: true,
        sortFunction: (a,b) => sortFunctionTreatingArraysAsStrings(a.transgressor,b.transgressor),
        wrap: true,
        minWidth: '150px',
      },
      {
        name: "Issue",
        selector: (row) => <div style={{ marginTop: 10 }}>{row.issue.map(x => <p>{x}<br/></p>)}</div>,
        sortable: true,
        sortFunction: (a,b) => sortFunctionTreatingArraysAsStrings(a.issue, b.issue),
        wrap: true,
        minWidth: '150px',
      },
    ],
    []
  );

  return (
    <div>
      <DataTable
        //@ts-ignore
        columns={columns}
        data={answers}
        pagination
        progressPending={loading}
        progressComponent={<Loader />}
        noHeader
        striped
      />
    </div>
  );
}
