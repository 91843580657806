/* takes a string phrase and breaks it into separate phrases
   no bigger than 'maxCharPerLine', breaks are made at complete words.

   Credit: https://stackoverflow.com/questions/21409717/chart-js-and-long-labels
*/

export default function splitStringToArrayByLength(
  text: string,
  maxCharPerLine: number
): Array<string> {
  var sections = [];
  var words = text.split(" ");
  var temp = "";

  words.forEach(function (item, index) {
    if (temp.length > 0) {
      var concat = temp + " " + item;

      if (concat.length > maxCharPerLine) {
        sections.push(temp);
        temp = "";
      } else {
        if (index == words.length - 1) {
          sections.push(concat);
          return;
        } else {
          temp = concat;
          return;
        }
      }
    }

    if (index == words.length - 1) {
      sections.push(item);
      return;
    }

    if (item.length < maxCharPerLine) {
      temp = item;
    } else {
      sections.push(item);
    }
  });

  return sections;
}
