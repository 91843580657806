import React, { useState } from "react";
import { Tab } from "react-bootstrap";
import OverviewTab from "./OverviewTab";
import GesturesTab from "./GesturesTab";
import PhysicalContactTab from "./PhysicalContactTab";
import ExclusionFavouritismTab from "./ExclusionFavouritismTab";
import WorkManagementTab from "./WorkManagementTab";
import WorkCommentsTab from "./WorkCommentsTab";
import GeneralCommentsTab from "./GeneralCommentsTab";
import SexualCommentsTab from "./SexualCommentsTab";
import SexualGesturesTab from "./SexualGesturesTab";
import PolicyViolationsTab from "./PolicyViolationsTab";
import StatementsTab from "./StatementsTab";
import InsightsTab from "./InsightsTab";
import Spacer from "../../../../Spacer";
import DateRangeButtonAndModal from "../../../../DatePickerButtonAndModal";
import RespondentCounter from "../RespondentCounter";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import Container from "react-bootstrap/Container";
import TabContainer from "../../../../TabContainer";

const tabs = [
  {
    name: "Overview",
    eventKey: "overview",
    Component: OverviewTab,
  },
  {
    name: "Gestures or Body Language",
    eventKey: "gestures",
    Component: GesturesTab,
  },
  {
    name: "Physical Contact",
    eventKey: "physicalContact",
    Component: PhysicalContactTab,
  },
  {
    name: "Exclusion or Favouritism",
    eventKey: "exclusionFavouritism",
    Component: ExclusionFavouritismTab,
  },
  {
    name: "Work Management Issues",
    eventKey: "workManagement",
    Component: WorkManagementTab,
  },
  {
    name: "Work-related Comments",
    eventKey: "workComments",
    Component: WorkCommentsTab,
  },
  {
    name: "General Comments",
    eventKey: "generalComments",
    Component: GeneralCommentsTab,
  },
  {
    name: "Sexual Comments",
    eventKey: "sexualComemnts",
    Component: SexualCommentsTab,
  },
  {
    name: "Sexual Gestures or Actions",
    eventKey: "sexualGestures",
    Component: SexualGesturesTab,
  },
  {
    name: "Policy Violations",
    eventKey: "policyViolations",
    Component: PolicyViolationsTab,
  },
  {
    name: "Statements",
    eventKey: "statements",
    Component: StatementsTab,
  },
  {
    name: "Insights",
    eventKey: "insights",
    Component: InsightsTab,
  },
];

const allDatesRange = {
  startDate: "1960-01-01",
  endDate: "2100-01-01",
};

export default function Dashboard() {
  const [respondentCount, setRespondentCount] = useState(0);
  const [confirmedDateRange, setConfirmedDateRange] = useState(allDatesRange);

  return (
    <Container>
      <TabContainer defaultActiveKey="overview">
        <Row>
          <Col sm={3}>
            <Nav className="flex-column">
              {tabs.map((tab) => {
                return (
                  <Nav.Item key={tab.eventKey}>
                    <Nav.Link eventKey={tab.eventKey}>{tab.name}</Nav.Link>
                  </Nav.Item>
                );
              })}
            </Nav>
            <Spacer height={15} />
            <RespondentCounter respondentCount={respondentCount} />
            <Spacer height={20} />
            <DateRangeButtonAndModal
              setConfirmedDateRange={setConfirmedDateRange}
            />
          </Col>
          <Col sm={6}>
            <Tab.Content>
              {tabs.map(({ Component, eventKey }) => {
                return (
                  <Tab.Pane
                    key={eventKey}
                    eventKey={eventKey}
                    mountOnEnter
                    unmountOnExit
                  >
                    <Component
                      dateRange={confirmedDateRange}
                      updateRespondentCounter={(newCount) => {
                        setRespondentCount(newCount);
                      }}
                    />
                  </Tab.Pane>
                );
              })}
            </Tab.Content>
          </Col>
        </Row>
      </TabContainer>
    </Container>
  );
}
