import React from "react";
import { covid19Colors } from "../../constants/colors";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { Doughnut as ChartJsDoughnut } from "react-chartjs-2";

type PropTypes = {
  data: any;
  options?: any;
  title?: string;
  tooltip?: string;
};

export default function Doughnut({ data, options, title, tooltip }: PropTypes) {
  const chartData = {
    datasets: [
      {
        data: Object.values(data),
        backgroundColor: Object.values(covid19Colors),
      },
    ],
    labels: Object.keys(data),
  };

  const chartOptions = {
    legend: {
      display: false,
    },
    ...options,
  };

  function renderTooltip(props) {
    return (
      <Tooltip id="scalar-tooltip" {...props}>
        {tooltip}
      </Tooltip>
    );
  }

  return (
    <div className="d-flex flex-column align-items-center mx-2">
      <OverlayTrigger
        placement="right"
        delay={{ show: 200, hide: 0 }}
        overlay={renderTooltip}
      >
        <div style={{ height: 60, width: 120 }}>
          <ChartJsDoughnut data={chartData} options={chartOptions} />
        </div>
      </OverlayTrigger>
      <p>{title}</p>
    </div>
  );
}
