import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import LeadsFormForRespondents from "./LeadsFormForRespondents";
import Spacer from "../Spacer";
import BackArrow from "../BackArrow";
import { colors } from "../../constants/colors";
import Logo from "../Logo";

function InterestedRespondent() {
  return (
    <div style={{ backgroundColor: colors.white }}>
      <BackArrow to="/anonymousReport" />
      <Container className="d-flex vh-100 flex-column justify-content-center align-items-center">
        <Logo lg />
        <Row>
          <Col>Please tell us about your company:</Col>
        </Row>
        <Spacer height={50} />
        <Row>
          <LeadsFormForRespondents />
        </Row>
      </Container>
    </div>
  );
}

export default InterestedRespondent;
