import React, { useContext } from "react";
import NavDropdown from "react-bootstrap/NavDropdown";
import OrganizationContext from "../../../contexts/OrganizationContext";
import { FORM_NAMES } from "line3_shared/constants/forms";
import './FormChooserDropdown.css';

export default function FormChooserDropdown() {
  const { activeFormId, setActiveFormId, availableFormIds = [] } = useContext(
    OrganizationContext
  );

  const options = availableFormIds.map((formId) => {
    return {
      label: FORM_NAMES.find((f) => f.id === formId).name,
      id: formId,
    };
  });
  const activeFormLabel =
    options.length > 0 && activeFormId
      ? options.find((f) => f.id === activeFormId).label
      : "";

  return (
    <NavDropdown
      title={activeFormLabel}
      id="basic-nav-dropdown"
      onSelect={(formId) => {
        setActiveFormId(formId);
      }}
      className='formChooserDropdown'
    >
      {options.map((form) => (
        <NavDropdown.Item key={form.id} eventKey={form.id}>
          {form.label}
        </NavDropdown.Item>
      ))}
    </NavDropdown>
  );
}
