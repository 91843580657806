import { Col } from "react-bootstrap";
import React from "react";
import { Link } from "react-router-dom";
import { MdArrowBack } from "react-icons/md";

export default function BackArrow({ to }) {
  return (
    <Col className="fixed-top pt-5 ml-3" style={{ width: 100 }}>
      <Link to={to} style={{ textDecoration: "none", color: "black" }}>
        <h1>
          <MdArrowBack />
        </h1>
      </Link>
    </Col>
  );
}
