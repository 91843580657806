import React from "react";

export default function LearnMoreSection({ content }) {
  return (
    <div>
      <h2 style={{ marginBottom: 20 }}>{content.title}</h2>
      <div style={{ marginBottom: 20 }}>{content.body}</div>
    </div>
  );
}
