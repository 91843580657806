import React from "react";
import BookCalendlyAppointment from "./BookCalendlyAppointment";

const CALENDLY_COACHING_SESSION_LINK =
  "https://calendly.com/canaryconsult/canary-coaching-session";

export default function BookCoachingSession() {
  return (
    <BookCalendlyAppointment calendlyLink={CALENDLY_COACHING_SESSION_LINK} />
  );
}
