import React from "react";
import { HorizontalBar as ChartJsHorizontalBar } from "react-chartjs-2";
import truncate from "lodash.truncate";

export default function HorizontalBar({
  data,
  labels,
  title,
  maxValue,
  palette,
}) {
  // this isn't actually the bar thickness, just a rough number used to get the height somewhat consistent
  const BAR_THICKNESS_MULTIPLIER = 12;
  // this is an approximation as well
  const TITLE_HEIGHT_ROUGHLY = 35;
  const TRUNCATE_TEXT_CHARACTERS = 25;
  const LABEL_WIDTH = 155;

  const chartData = {
    labels,
    datasets: [
      {
        data,
        fill: false,
        backgroundColor: palette,
        borderWidth: 1,
      },
    ],
  };

  const chartOptions = {
    scales: {
      xAxes: [
        {
          ticks: {
            beginAtZero: true,
            suggestedMax: maxValue,
            precision: 0,
          },
        },
      ],
      yAxes: [
        {
          ticks: {
            callback: (value) => {
              return truncate(value, { length: TRUNCATE_TEXT_CHARACTERS });
            },
          },
          afterFit: (me) => {
            me.width = LABEL_WIDTH;
          },
        },
      ],
    },
    legend: {
      display: false,
    },
    title: {
      display: true,
      text: title,
    },
  };

  return (
    <ChartJsHorizontalBar
      data={chartData}
      options={chartOptions}
      height={BAR_THICKNESS_MULTIPLIER * data.length + TITLE_HEIGHT_ROUGHLY}
    />
  );
}
