import React from "react";
import whiteShirtWoman from "../../../static/learnMore/red-hair-woman-white-shirt.png";
import beardedBlueShirtMan from "../../../static/learnMore/bearded-man-blue-shirt.png";
import laptopWoman from "../../../static/learnMore/laptop-lady-facing-right.png";
import maskedWoman from "../../../static/learnMore/gray-hair-woman-with-mask.png";
import ScenarioAccordion from "./ScenarioAccordion";

const smallStartup = {
  title: " A small startup with no HR and lots of conflict",
  image: whiteShirtWoman,
  workplace: (
    <div>
      A Fintech startup is rising to success. With a couple of angel investors
      and a lean team, they are poised to launch a premium subscription service
      within a year. Things are looking good.
    </div>
  ),
  problem: (
    <div>
      One of the two Product Leads, Skyler is constantly getting into fights
      with the COO, Raven. From Skyler's point of view, Raven is always
      dismissive of their ideas and whenever Skyler is successful, Raven never
      gives praise. To Skyler, It almost feels like harassment or bullying.
      Since it's a small team and Raven is technically responsible for HR
      issues, Skyler can only talk to the CEO about this. But the CEO and Raven
      are best friends and the co-founders of the startup, so Skyler feels like
      the CEO will always side with Raven.
    </div>
  ),
  solution: (
    <div>
      The startup purchases a <strong>Canary Coach</strong> package. Skyler
      calls a neutral, third-party expert in conflict resolution to have an
      off-the-record conversation about how to sort through their issues, look
      at the problem objectively, and figure out a way to resolve it. Skyler
      gains the tools to have an honest conversation with the CEO and Raven in a
      way that is non-adversarial and conducive to a more productive and healthy
      working relationship.
    </div>
  ),
};

const employeesWontSpeakUp = {
  title:
    "An organization that knows something's wrong but employees won't speak up",
  image: beardedBlueShirtMan,
  workplace: (
    <div>
      A restaurant chain with more than 30 locations requires every new hire to
      watch the same 15-minute video on workplace harassment as part of their
      training. Since employees need to maintain good relationships with
      managers to get preferred shifts, they often don't speak up when these
      types of issues arise.
    </div>
  ),
  problem: (
    <div>
      Over the last few years there have been a growing number of complaints of
      sexual harassment. Perplexingly, these complaints are only brought up
      months or even years after the incidents have occurred. The investigations
      reveal that the inappropriate comments were said by employees who just did
      not realize that what they were saying was inappropriate.
    </div>
  ),
  solution: (
    <div>
      The restaurant chain purchases Canary's signature anonymous complaint
      reporting tool, which allows employees to answer a series of questions to
      help the employer specify the types of concerns that they have without
      triggering an investigation. The reports reveal that employees are making
      inappropriate comments about both gender and race. The restaurant chain
      then trains everyone on those two specific issues and prevents those types
      of comments from recurring.
    </div>
  ),
};

const wantsAnonymousFeedback = {
  title:
    "An organization that wants a simple, secure way to give anonymous feedback",
  image: laptopWoman,
  workplace: (
    <div>
      A consulting firm with about 500 employees operates across two countries
      and has a robust human resources team. They conduct regular pulse-check
      surveys and use an anonymous feedback tool. Employees tend to stay at the
      organization for a long time and work well together.
    </div>
  ),
  problem: (
    <div>
      The consulting firm's anonymous feedback tool isn't being used. Many
      employees don't even know it exists. The tool is pretty expensive and it's
      hard to justify the cost when so few people use it. Even though the tool
      has its own sophisticated case management system, it's cumbersome and does
      not get enough reports to justify its use.
    </div>
  ),
  solution: (
    <div>
      The consulting firm purchases Canary's basic anonymous reporting tool.
      It's more cost-effective, it's straightforward, and it fulfills the same
      function of allowing employees to submit concerns and feedback
      anonymously. The consulting firm also receives a discount on WG
      Resolutions' workplace harassment training, which promotes the tool so
      everyone knows how to use it when it's necessary. Employees are happy and
      relieved to know that they can use Canary when the time is right.
    </div>
  ),
};

const covidSafetyIssues = {
  title: "An organization that needs to report specific COVID-19 safety issues",
  image: maskedWoman,
  workplace: (
    <div>
      A company owns and operates retirement homes in several locations and it
      needs to meet rigorous sanitation standards to ensure every home is free
      of COVID-19. There are internal health and safety officers for each home
      who are responsible for ensuring that employees and visitors comply with
      these standards.
    </div>
  ),
  problem: (
    <div>
      A resident contracted COVID-19 and an internal investigation revealed that
      one or more employees did not consistently screen visitors. More
      importantly, it was clear that this was something other employees knew
      about but never reported to a health and safety officer.
    </div>
  ),
  solution: (
    <div>
      The company worked with Canary to create an anonymous reporting tool where
      employees can report any issues with screening visitors for COVID-19, the
      availability of PPE and sanitizer, and violations of sanitation procedures
      for each home. One of the internal health and safety officers uses this
      tool as a precautionary measure. When a report comes in they can attend at
      the specified location to confirm that all sanitation procedures are being
      followed.
    </div>
  ),
};

const scenarios = [
  smallStartup,
  employeesWontSpeakUp,
  wantsAnonymousFeedback,
  covidSafetyIssues,
];

export default function ExampleScenarios() {
  return (
    <div>
      {scenarios.map((scenario, index) => {
        return <ScenarioAccordion scenario={scenario} key={index} />;
      })}
    </div>
  );
}
