import React from "react";
import { Container, Col } from "react-bootstrap";

export default function NotFound() {
  return (
    <Container className="d-flex vh-100 justify-content-center align-items-center">
      <Col xs={6} md={4} className="d-flex flex-column align-items-center">
        <p>Page Not Found</p>
      </Col>
    </Container>
  );
}
