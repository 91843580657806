import React from "react";
import { Tab } from "react-bootstrap";
import DiscriminationTab from "./DiscriminationTab";
import HarassmentTab from "./HarassmentTab";
import ViolenceTab from "./ViolenceTab";
import OverviewTab from "./OverviewTab";
import FraudTab from "./FraudTab";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import Container from "react-bootstrap/Container";
import TabContainer from "../../../../TabContainer";

export default function Dashboard() {
  return (
    <Container>
      <TabContainer defaultActiveKey="overview">
        <Row>
          <Col sm={3}>
            <Nav className="flex-column">
              <Nav.Item>
                <Nav.Link eventKey="overview">Overview</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="discrimination">Discrimination</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="harassment">Harassment</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="violence">Violence</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="fraud">Fraud</Nav.Link>
              </Nav.Item>
            </Nav>
          </Col>
          <Col sm={6}>
            <Tab.Content>
              <Tab.Pane eventKey="overview">
                <OverviewTab />
              </Tab.Pane>
              <Tab.Pane eventKey="discrimination">
                <DiscriminationTab />
              </Tab.Pane>
              <Tab.Pane eventKey="harassment">
                <HarassmentTab />
              </Tab.Pane>
              <Tab.Pane eventKey="violence">
                <ViolenceTab />
              </Tab.Pane>
              <Tab.Pane eventKey="fraud">
                <FraudTab />
              </Tab.Pane>
            </Tab.Content>
          </Col>
        </Row>
      </TabContainer>
    </Container>
  );
}
