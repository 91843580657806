import React from "react";
import { forms } from "line3_shared/constants/forms";
import HorizontalBar2 from "../../../../../charts/HorizontalBar2";
import { concernsColorPalette } from "../../../../../../constants/colors";

export default function IncidentTargetChart({ filter }) {
  const incidentTarget = {
    formId: forms.concerns,
    buckets: [
      {
        label: "Managers or Leaders",
        match: {
          or: [
            { questionId: "KQtVCvXOUyTz", answerId: "vbxv99xgZBfh" }, // An individual
            { questionId: "CQUMzX6ulFVJ", answerId: "9w3G2wefzmFl" }, // A group of people
          ],
        },
      },
      {
        label: "Employees or Contractors",
        match: {
          or: [
            { questionId: "KQtVCvXOUyTz", answerId: "XgEez6JGpJtk" }, // An individual
            { questionId: "CQUMzX6ulFVJ", answerId: "JnNWeZflgD2a" }, // A group of people
          ],
        },
      },
      {
        label: "Vendors or Clients",
        match: {
          or: [
            { questionId: "KQtVCvXOUyTz", answerId: "rW0smXkzk9ms" }, // An individual
            { questionId: "CQUMzX6ulFVJ", answerId: "jA5cIbbEKe1C" }, // A group of people
          ],
        },
      },
      {
        label: "Someone else",
        match: {
          or: [
            { questionId: "KQtVCvXOUyTz", answerId: "T4tnNTcCQy3O" }, // An individual
            { questionId: "CQUMzX6ulFVJ", answerId: "UKqjic3pFbCY" }, // A group of people
          ],
        },
      },
    ],
    filter,
  };

  return (
    <HorizontalBar2
      title="Targets"
      palette={concernsColorPalette}
      query={incidentTarget}
    />
  );
}
