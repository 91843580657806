import React from "react";

export default function Checkbox({ checked, onChange, text }) {
  return (
    <div className="d-flex flex-row align-items-center">
      <input
        type="checkbox"
        checked={checked}
        onChange={onChange}
      />
      {text && <p className="mb-0 ml-3">{text}</p>}
    </div>
  );
}
