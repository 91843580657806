import React, { useEffect, useState } from "react";
import Loader from "../../../../Loader";
import HorizontalBar from "../../../../charts/HorizontalBar";
import { covid19Colors } from "../../../../../constants/colors";
import ScalarScore from "../../../../charts/ScalarScore";
import NoResponses from "./NoResponses";
import Doughnut from "../../../../charts/Doughnut";

const issueLabels = [
  "Scheduling (including work hours, and who is allowed to go to the workplace)",
  "Working from home",
  "Travel to and from the workplace (including transportation, elevators, entry and exit points)",
  "Air circulation",
  "Physical barriers (Curtains, plexiglass)",
  "Privacy of health information",
  "Personal health issues (including compromised immunity from other medical conditions)",
  "Other",
];

export default function OtherTab({
  dateRange,
  projectsFilter,
  updateRespondentCounter,
}) {
  const [results, setResults] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function requestInitialData() {
      setLoading(true);
      const res = await fetch(
        `/api/covid19BuildingUp/calculateOther?startDate=${dateRange.startDate}&endDate=${dateRange.endDate}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ projectsFilter }),
        }
      );
      const results = await res.json();
      setResults(results);
      updateRespondentCounter(results.total);
      setLoading(false);
    }
    requestInitialData();
  }, [updateRespondentCounter, dateRange, projectsFilter]);

  if (loading || !results) return <Loader />;

  if (results.total === 0) {
    return (
      <NoResponses
        text="No other uncategorized concerns have been reported."
        height={400}
      />
    );
  }

  const issueData = issueLabels.map((label) => results.otherIssue[label]);

  return (
    <div>
      <HorizontalBar
        data={issueData}
        labels={issueLabels}
        title="What are these concerns about?"
        maxValue={results.total}
        palette={covid19Colors.other}
      />
      <div className="d-flex flex-row">
        <Doughnut
          data={results.frequency}
          title="Frequency"
          tooltip="How frequently has this occurred?"
        />
        <ScalarScore
          score={results.intensity}
          title="Intensity"
          tooltip="How serious are these safety concerns, from your perspective? (1 to 10)"
        />
        <Doughnut
          data={results.timePeriod}
          title="Time Period"
          tooltip="This occurred during the last..."
        />
        <Doughnut
          data={results.repeat}
          title="Repeat"
          tooltip="Is this the first time you are reporting this issue?"
        />
      </div>
    </div>
  );
}
