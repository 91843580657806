import React, { useContext, useEffect, useState } from "react";
import { DataType } from "line3_shared/types/DataType";
import DateRangeContext from "../../../../../contexts/DateRangeContext";
import { forms } from "line3_shared/constants/forms";
import { StatementQueryType } from "line3_shared/types/StatementQueryType";
import "./StatementsTab.scss";
import Loader from "../../../../Loader";
import Statements from "../../../../charts/Statements";

export default function StatementsTab({ updateRespondentCounter }) {
  const { dateRange } = useContext(DateRangeContext);
  const [statements, setStatements] = useState<DataType | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  const statementsFilter = {
    or: [
      {
        questionId: "iisuvVs6c6yX",
        answerId: true,
      },
    ],
  };

  const query: StatementQueryType = {
    formId: forms.concerns,
    filter: statementsFilter,
    statementQuestionId: "zukWP6uBW5ts",
    emailQuestionId: "u5k8g32qa6i9",
  };

  useEffect(() => {
    async function requestData() {
      setLoading(true);
      const data = {
        dateRange,
        query,
      };
      const res = await fetch(`/api/statements`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data),
      });
      const results = await res.json();
      setStatements(results);
      updateRespondentCounter(results.length);
      setLoading(false);
    }
    requestData();
  }, [dateRange]); // eslint-disable-line react-hooks/exhaustive-deps

  if (loading) return <Loader />;

  return (
    <div className="statementsContainer">
      <Statements statements={statements} />
    </div>
  );
}
