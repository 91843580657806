import React from "react";
import { colors } from "../../../../constants/colors";

export default function RespondentCounter({ respondentCount }) {
  return (
    <div className="d-flex align-items-center justify-content-left">
      <div
        className="d-flex justify-content-center align-items-center mx-2"
        style={{
          border: "solid 5px",
          borderColor: colors.primary,
          borderRadius: "50%",
          color: colors.primary,
          height: 60,
          width: 60,
        }}
      >
        <h4 className="mb-0">{respondentCount}</h4>
      </div>
      <h4 className="mb-0" style={{ color: colors.primary }}>
        Report{respondentCount === 1 ? "" : "s"}
      </h4>
    </div>
  );
}
