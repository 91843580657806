import React, { useEffect } from "react";
import HorizontalBar2 from "../../../../charts/HorizontalBar2";
import IdentityTargetedChart, {
  IDENTITY_BUCKETS_TYPE,
} from "./charts/IdentityTargetedChart";
import IncidentTargetChart from "./charts/IncidentTargetChart";
import IncidentTransgressorChart from "./charts/IncidentTransgressorChart";
import BottomDoughnuts from "./charts/BottomDoughnuts";
import { forms } from "line3_shared/constants/forms";
import { concernsColorPalette } from "../../../../../constants/colors";
import Loader from "../../../../Loader";
import useGetDataFromQuery from "../../../../../hooks/useGetDataFromQuery";

export default function WorkManagementTab({
  dateRange,
  updateRespondentCounter,
}) {
  const workManagementFilter = {
    or: [
      {
        questionId: "h9aCykuWSqZG",
        answerId: true,
      },
    ],
  };

  const { loading, data } = useGetDataFromQuery({
    formId: forms.concerns,
    questionId: "hxevrGkgspp5",
    filter: workManagementFilter,
  });

  useEffect(() => {
    if (data) {
      updateRespondentCounter(data.total);
    }
  }, [updateRespondentCounter, dateRange, data]);

  if (loading) return <Loader />;

  const behavioursReported = {
    formId: forms.concerns,
    buckets: [
      {
        label: "Unequal distribution of work",
        match: { questionId: "qiijfdHOhpmQ", answerId: "wuTRI63yb0W2" },
      },
      {
        label: "Unfair change in distribution of work",
        match: { questionId: "qiijfdHOhpmQ", answerId: "402vnuZGkuod" },
      },
      {
        label: "Unequal distribution of positive feedback",
        match: { questionId: "qiijfdHOhpmQ", answerId: "k6dlcGFE3Hq9" },
      },
      {
        label: "Unfair distribution of career growth opportunities",
        match: { questionId: "qiijfdHOhpmQ", answerId: "Q1PKAxVS3UEI" },
      },
      {
        label: "Unwarranted criticism",
        match: { questionId: "qiijfdHOhpmQ", answerId: "jdB5u4MuMDBO" },
      },
      {
        label: "Work assignments that are outside job description",
        match: { questionId: "qiijfdHOhpmQ", answerId: "xt7qLqWMmgg8" },
      },
      {
        label: "Setting unreasonable deadlines or expectations",
        match: { questionId: "qiijfdHOhpmQ", answerId: "h4pchLUwYWb8" },
      },
      {
        label: "Lack of resources/support",
        match: { questionId: "qiijfdHOhpmQ", answerId: "4Eqi9Xvvbfe8" },
      },
      {
        label: "Lack of clear instructions",
        match: { questionId: "qiijfdHOhpmQ", answerId: "FSHvXoUtUW4O" },
      },
      {
        label: "Withholding relevant information",
        match: { questionId: "qiijfdHOhpmQ", answerId: "0GpltVO5nAWS" },
      },
      {
        label: "Micromanaging",
        match: { questionId: "qiijfdHOhpmQ", answerId: "ENXhQbopkdnr" },
      },
      {
        label: "The person does not have the skills to do their job",
        match: { questionId: "qiijfdHOhpmQ", answerId: "obnoxiR9Ioj3" },
      },
      {
        label: "Other",
        match: { questionId: "qiijfdHOhpmQ", answerId: "Ue3fXLBkgIjS" },
      },
    ],
  };

  return (
    <div>
      <HorizontalBar2
        title="Work Management Issue"
        palette={concernsColorPalette}
        query={behavioursReported}
      />
      <IdentityTargetedChart
        filter={workManagementFilter}
        identityBucketsType={IDENTITY_BUCKETS_TYPE.GENERAL}
      />
      <IncidentTargetChart filter={workManagementFilter} />
      <IncidentTransgressorChart filter={workManagementFilter} />
      <BottomDoughnuts filter={workManagementFilter} />
    </div>
  );
}
