import React, { useEffect } from "react";
import HorizontalBar2 from "../../../../charts/HorizontalBar2";
import IdentityTargetedChart, {
  IDENTITY_BUCKETS_TYPE,
} from "./charts/IdentityTargetedChart";
import IncidentTargetChart from "./charts/IncidentTargetChart";
import IncidentTransgressorChart from "./charts/IncidentTransgressorChart";
import BottomDoughnuts from "./charts/BottomDoughnuts";
import { forms } from "line3_shared/constants/forms";
import { concernsColorPalette } from "../../../../../constants/colors";
import Loader from "../../../../Loader";
import useGetDataFromQuery from "../../../../../hooks/useGetDataFromQuery";

export default function GesturesTab({ dateRange, updateRespondentCounter }) {
  const gesturesFilter = {
    or: [
      {
        questionId: "lXcP5TKUJy1j",
        answerId: true,
      },
    ],
  };

  const { loading, data } = useGetDataFromQuery({
    formId: forms.concerns,
    questionId: "hxevrGkgspp5",
    filter: gesturesFilter,
  });

  useEffect(() => {
    if (data) {
      updateRespondentCounter(data.total);
    }
  }, [updateRespondentCounter, dateRange, data]);

  if (loading) return <Loader />;

  const behavioursReported = {
    formId: forms.concerns,
    buckets: [
      {
        label: "Hand/arm gestures",
        match: { questionId: "kDpVqncMSOE5", answerId: true },
      },
      {
        label: "Eye gestures or gazing",
        match: { questionId: "unojU4sBkbx3", answerId: true },
      },
      {
        label: "Facial gestures",
        match: { questionId: "Joeo8dkL5sbo", answerId: true },
      },
      {
        label: "Body gestures or body language",
        match: { questionId: "aGtPTMdBZuSd", answerId: true },
      },
    ],
  };

  // TO DO: add more specific charts for each gesture category (types of arm gestures, types of eye gestures, types of facial gestures, types of body gestures)

  return (
    <div>
      <HorizontalBar2
        title="Gestures Reported"
        palette={concernsColorPalette}
        query={behavioursReported}
      />
      <IdentityTargetedChart
        filter={gesturesFilter}
        identityBucketsType={IDENTITY_BUCKETS_TYPE.GENERAL}
      />
      <IncidentTargetChart filter={gesturesFilter} />
      <IncidentTransgressorChart filter={gesturesFilter} />
      <BottomDoughnuts filter={gesturesFilter} />
    </div>
  );
}
