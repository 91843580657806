import React, { useState } from "react";
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
import Image from "react-bootstrap/Image";
import "./ScenarioAccordion.scss";

export default function ScenarioAccordion({ scenario }) {
  const [accordionOpen, setAccordionOpen] = useState(false);

  const { image, workplace, problem, solution } = scenario;

  return (
    <Accordion className="customAccordion" style={{ marginBottom: 10 }}>
      <Card style={{ borderColor: "black" }}>
        <Accordion.Toggle
          as={Card.Header}
          eventKey={true}
          onClick={() => {
            setAccordionOpen(!accordionOpen);
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              cursor: "pointer",
              fontWeight: "bold",
              borderBottom: "black",
            }}
          >
            <div style={{ flexBasis: "90%" }}>{scenario.title}</div>
            <div
              className={accordionOpen === true ? "flipVertically" : ""}
              style={{ alignSelf: "center" }}
            >
              &#9660;
            </div>
          </div>
        </Accordion.Toggle>
        <Accordion.Collapse eventKey={true}>
          <Card.Body>
            <div style={{ padding: 20 }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginBottom: 10,
                }}
              >
                <Image src={image} style={{ maxHeight: 150 }} />
              </div>
              <div className="accordionSection">
                <div className="accordionHeader">The Workplace</div>
                <div>{workplace}</div>
              </div>
              <div className="accordionSection">
                <div className="accordionHeader">The Problem</div>
                <div>{problem}</div>
              </div>
              <div className="accordionSection">
                <div className="accordionHeader">Canary's Solution</div>
                {solution}
              </div>
            </div>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    </Accordion>
  );
}
