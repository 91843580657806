import React from "react";
import './TabContainer.css';
import Tab from "react-bootstrap/Tab";

export default function TabContainer({ children, ...otherProps }) {
  return <div className="tabContainer">
    <Tab.Container {...otherProps}>
      {children}
    </Tab.Container>
  </div>;
}
