import React from "react";
import "./FinePrint.scss";

export default function FinePrint() {
  return (
    <div className="finePrint">
      <p>
        Images by{" "}
        <a
          href="https://www.openpeeps.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Open Peeps
        </a>
        , Pablo Stanley /{" "}
        <a
          href="https://creativecommons.org/publicdomain/zero/1.0/"
          target="_blank"
          rel="noopener noreferrer"
        >
          CCO
        </a>
      </p>
    </div>
  );
}
