import React, { useState } from "react";
import { Button, Form, Container, Col, Image, Alert } from "react-bootstrap";

import logo from "../../static/canary-1@3x.png";
import { colors } from "../../constants/colors";

import "../FormField.scss";

export default function Signup() {
  const [errors, setErrors] = useState([]);
  const [successMessage, setSuccessMessage] = useState("");

  function submitForm(event) {
    setErrors([]);
    setSuccessMessage("");

    event.preventDefault();

    const formInputs = event.target.elements;
    const body = {
      email: formInputs.formBasicEmail.value,
      password: formInputs.formBasicPassword.value,
      passwordConfirmation: formInputs.formBasicPasswordConfirmation.value,
    };

    if (body.password !== body.passwordConfirmation) {
      setErrors(["Passwords did not match"]);
      return;
    }

    fetch("/api/signup", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    })
      .then((response) => {
        if (response.ok) {
          response.json().then((json) => {
            setSuccessMessage(json.message);
          });
        } else {
          response.json().then((json) => {
            setErrors([json.message]);
          });
        }
      })
      .catch((err) => {
        setErrors(["Registration failed. Please try again."]);
      });
  }

  return (
    <div style={{ backgroundColor: colors.white }}>
      <Container className="d-flex vh-100 justify-content-center align-items-center">
        <Col xs={6} md={4} className="d-flex flex-column align-items-center">
          <h3>Canary</h3>
          <Image fluid src={logo} className="w-25 mb-3" />
          <Form onSubmit={submitForm}>
            <div className="d-flex flex-column align-items-center">
              <Form.Group controlId="formBasicEmail">
                <Form.Control
                  type="text"
                  placeholder="example@company.com"
                  autofocus="autofocus"
                  className="customBorder"
                />
              </Form.Group>

              <Form.Group controlId="formBasicPassword">
                <Form.Control
                  type="password"
                  placeholder="Password"
                  className="customBorder"
                />
              </Form.Group>

              <Form.Group controlId="formBasicPasswordConfirmation">
                <Form.Control
                  type="password"
                  placeholder="Confirm password"
                  className="customBorder"
                />
              </Form.Group>

              <Button
                style={{
                  backgroundColor: "#4BC084",
                  border: "1px solid lightgrey",
                }}
                type="submit"
              >
                Sign up
              </Button>
            </div>
          </Form>
          <div className="mt-3">
            {errors.length > 0 &&
              errors.map((error, index) => {
                return (
                  <Alert key={index} variant={"danger"}>
                    {error}
                  </Alert>
                );
              })}
            {successMessage.length > 0 && (
              <Alert variant="success">{successMessage}</Alert>
            )}
          </div>
        </Col>
        <Col className="fixed-bottom">
          <p>info@canary.report</p>
        </Col>
      </Container>
    </div>
  );
}
