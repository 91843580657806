import React, { useEffect } from "react";
import HorizontalBar2 from "../../../../charts/HorizontalBar2";
import IdentityTargetedChart, {
  IDENTITY_BUCKETS_TYPE,
} from "./charts/IdentityTargetedChart";
import IncidentTargetChart from "./charts/IncidentTargetChart";
import IncidentTransgressorChart from "./charts/IncidentTransgressorChart";
import BottomDoughnuts from "./charts/BottomDoughnuts";
import { forms } from "line3_shared/constants/forms";
import { concernsColorPalette } from "../../../../../constants/colors";
import Loader from "../../../../Loader";
import useGetDataFromQuery from "../../../../../hooks/useGetDataFromQuery";

export default function SexualGesturesTab({
  dateRange,
  updateRespondentCounter,
}) {
  const sexualGesturesFilter = {
    or: [
      {
        questionId: "ijNYixvWCRDN",
        answerId: true,
      },
    ],
  };

  const { loading, data } = useGetDataFromQuery({
    formId: forms.concerns,
    questionId: "hxevrGkgspp5",
    filter: sexualGesturesFilter,
  });

  useEffect(() => {
    if (data) {
      updateRespondentCounter(data.total);
    }
  }, [updateRespondentCounter, dateRange, data]);

  if (loading) return <Loader />;

  const behavioursReported = {
    formId: forms.concerns,
    buckets: [
      {
        label: "Exposing oneself",
        match: { questionId: "TJNyPIrFg65V", answerId: "Fy8LFlM3Tt6S" },
      },
      {
        label: "Touching oneself in an intimate manner",
        match: { questionId: "TJNyPIrFg65V", answerId: "ZkHGTdrDr2sV" },
      },
      {
        label: "Making other sexual gestures",
        match: { questionId: "TJNyPIrFg65V", answerId: "jwqSn0VTNH4o" },
      },
      {
        label: "Showing or looking at pornography or other sexual material",
        match: { questionId: "TJNyPIrFg65V", answerId: "26qNyzOhwfZy" },
      },
      {
        label: "Other",
        match: { questionId: "TJNyPIrFg65V", answerId: "Ncb6FycUr3DJ" },
      },
    ],
  };

  return (
    <div>
      <HorizontalBar2
        title="Sexual Gestures Reported"
        palette={concernsColorPalette}
        query={behavioursReported}
      />
      <IdentityTargetedChart
        filter={sexualGesturesFilter}
        identityBucketsType={IDENTITY_BUCKETS_TYPE.GENERAL}
      />
      <IncidentTargetChart filter={sexualGesturesFilter} />
      <IncidentTransgressorChart filter={sexualGesturesFilter} />
      <BottomDoughnuts filter={sexualGesturesFilter} />
    </div>
  );
}
