import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Spinner from "react-bootstrap/Spinner";
import Table from "react-bootstrap/Table";
import Alert from "react-bootstrap/Alert";
import Spacer from "../../../Spacer";
import { SOURCE_TYPE } from "../../../../constants/leads";
import { colors } from "../../../../constants/colors";

export default function ViewLeads() {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState([]);
  const [leads, setLeads] = useState([]);

  useEffect(() => {
    setLoading(true);
    fetch("/api/leads").then((response) => {
      if (response.ok) {
        response.json().then((json) => {
          setLeads(json.leads);
        });
      } else {
        if (response.status === 401) {
          history.push("/app/login");
        } else {
          response.json().then((json) => {
            setErrors(["Something went wrong. Reload and try again"]);
          });
        }
      }
      setLoading(false);
    });
  }, [history]);

  if (errors.length !== 0) {
    console.log("There is an error.");
    return (
      <div className="mt-3 align-center">
        {errors.length > 0 &&
          errors.map((error, index) => {
            return (
              <Alert key={index} variant={"danger"}>
                {error}
              </Alert>
            );
          })}
      </div>
    );
  }

  if (loading) {
    return (
      <div className="d-flex justify-content-center align-items-center">
        <Spinner animation="border" style={{ color: "#4BC084" }} />
      </div>
    );
  }

  if (leads.length === 0) {
    return <p>No leads yet!</p>;
  }

  const employerLeads = leads
    .filter((lead) => {
      return lead.sourceType === SOURCE_TYPE.EMPLOYER;
    })
    .reverse(); // most recent first
  const respondentLeads = leads
    .filter((lead) => {
      return lead.sourceType !== SOURCE_TYPE.EMPLOYER;
    })
    .reverse(); // most recent first

  return (
    <div>
      <EmployerLeadsTable leads={employerLeads} />
      <Spacer height={50} />
      <RespondentLeadsTable leads={respondentLeads} />
      <Spacer height={50} />
    </div>
  );
}

function EmployerLeadsTable({ leads }) {
  return (
    <>
      <h3 style={{ color: colors.secondaryBlue }}>Employer Leads</h3>
      <Spacer height={10} />
      {leads.length === 0 ? (
        "No employer leads yet."
      ) : (
        <Table>
          <thead>
            <tr>
              <th>Name</th>
              <th>Email</th>
              <th>Company</th>
              <th>Job</th>
              <th>Location</th>
              <th>Interest</th>
              <th>Time</th>
            </tr>
          </thead>
          <tbody>
            {leads.map((lead, index) => {
              return (
                <tr key={index}>
                  <td>{lead.name}</td>
                  <td>{lead.email}</td>
                  <td>{lead.company}</td>
                  <td>{lead.job}</td>
                  <td>
                    {lead.city ? `${lead.city}, ${lead.country || "N/A"}` : ""}
                  </td>
                  <td>{lead.interestedIn.join("; ")}</td>
                  <td>{new Date(lead.createdAt).toString().slice(4, 24)}</td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      )}
    </>
  );
}

function RespondentLeadsTable({ leads }) {
  return (
    <>
      <h3 style={{ color: colors.secondaryBlue }}>Respondent Leads</h3>
      <Spacer height={10} />
      {leads.length === 0 ? (
        "No respondent leads yet."
      ) : (
        <Table>
          <thead>
            <tr>
              <th>Company</th>
              <th>Contact Person</th>
              <th>Company Email</th>
              <th>Location</th>
              <th>Time</th>
            </tr>
          </thead>
          <tbody>
            {leads.map((lead, index) => {
              return (
                <tr key={index}>
                  <td>{lead.name}</td>
                  <td>{lead.email}</td>
                  <td>{lead.company}</td>
                  <td>
                    {lead.city ? `${lead.city}, ${lead.country || "N/A"}` : ""}
                  </td>
                  <td>{new Date(lead.createdAt).toString().slice(4, 24)}</td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      )}
    </>
  );
}
