import React, { useState } from "react";
import { Tab } from "react-bootstrap";
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";

import Row from "react-bootstrap/Row";
import DateRangeButtonAndModal from "../../../../DatePickerButtonAndModal";
import Spacer from "../../../../Spacer";
import TabContainer from "../../../../TabContainer";
import RespondentCounter from "../RespondentCounter";
import HarassementAndBullyingBehaviourTab from "./HarassmentAndBullyingBehaviourTab";
import OverviewTab from "./OverviewTab";
import ReportsTab from "./ReportsTab";
import WorkplaceCultureIssuesTab from "./WorkplaceCultureIssuesTab";

const TABS = [
  {
    name: "Overview",
    eventKey: "overview",
    Component: OverviewTab,
  },
  {
    name: "Workplace Culture Issues",
    eventKey: "workplaceCultureIssues",
    Component: WorkplaceCultureIssuesTab,
  },
  {
    name: "Harassment and Bullying Behaviour",
    eventKey: "harassmentAndBullyingBehaviour",
    Component: HarassementAndBullyingBehaviourTab,
  },
  {
    name: "Reports",
    eventKey: "reports",
    Component: ReportsTab,
  },
];

const allDatesRange = {
  startDate: "1960-01-01",
  endDate: "2100-01-01",
};

export default function Dashboard() {
  const [respondentCount, setRespondentCount] = useState(0);

  return (
    <TabContainer defaultActiveKey="overview">
      <Row className="px-5">
        <Col sm={3}>
          <Nav className="flex-column">
            {TABS.map((tab) => {
              return (
                <Nav.Item key={tab.eventKey}>
                  <Nav.Link eventKey={tab.eventKey}>{tab.name}</Nav.Link>
                </Nav.Item>
              );
            })}
          </Nav>
          <Spacer height={15} />
          <RespondentCounter respondentCount={respondentCount} />
          <Spacer height={20} />
          <DateRangeButtonAndModal />
        </Col>
        <Col sm={9} lg={9}>
          <Tab.Content>
            {TABS.map(({ Component, eventKey }) => {
              return (
                <Tab.Pane
                  key={eventKey}
                  eventKey={eventKey}
                  mountOnEnter
                  unmountOnExit
                >
                  <div style={eventKey !== "reports" ? { width: "66%" } : {}}>
                    <Component
                      updateRespondentCounter={(newCount) => {
                        setRespondentCount(newCount);
                      }}
                    />
                  </div>
                </Tab.Pane>
              );
            })}
          </Tab.Content>
        </Col>
      </Row>
    </TabContainer>
  );
}
