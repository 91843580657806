import React from "react";
import Container from "react-bootstrap/Container";

type PropTypes = {
  text: string;
  height: "auto" | number;
};

export default function NoResponses({ text, height = "auto" }: PropTypes) {
  return (
    <Container
      style={{ height }}
      className="d-flex justify-content-center align-items-center"
    >
      <div>{text}</div>
    </Container>
  );
}
