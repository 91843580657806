import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Button, Form, Col, Alert } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";

export default function AddOrganization() {
  let history = useHistory();
  const [errors, setErrors] = useState([]);
  const [successMessage, setSuccessMessage] = useState("");

  function createOrganization(event) {
    setErrors([]);
    setSuccessMessage("");

    event.preventDefault();

    const formInputs = event.target.elements;
    const body = {
      name: formInputs.formOrganizationName.value,
      password: formInputs.formOrganizationPassword.value,
    };

    fetch("/api/organizations", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    })
      .then((response) => {
        if (response.ok) {
          response.json().then((json) => {
            setSuccessMessage(json.message);
          });
        } else {
          if (response.status === 401) {
            setErrors(["You do not have permission to perform that action"]);
            setTimeout(() => {
              history.push("/app/login");
            }, 3000);
          } else {
            response.json().then((json) => {
              setErrors([json.message]);
            });
          }
        }
      })
      .catch((err) => {
        setErrors(["Something went wrong. Please try again."]);
      });
  }

  return (
    <Card>
      <Card.Header>Add a new Organization</Card.Header>
      <Card.Body>
        <Form onSubmit={createOrganization}>
          <div className="d-flex flex-column">
            <Form.Group as={Row} controlId="formOrganizationName">
              <Form.Label column sm="4">
                Organization Name
              </Form.Label>
              <Col>
                <Form.Control
                  type="text"
                  placeholder="Megacorp Inc."
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row} controlId="formOrganizationPassword">
              <Form.Label column sm="4">
                Survey Password
              </Form.Label>
              <Col>
                <Form.Control type="text" placeholder="banana-paper-kite" />
              </Col>
            </Form.Group>

            <Button type="submit">Create Organization</Button>
          </div>
        </Form>
        <div className="mt-3">
          {errors.length > 0 &&
            errors.map((error, index) => {
              return (
                <Alert key={index} variant={"danger"}>
                  {error}
                </Alert>
              );
            })}
          {successMessage.length > 0 && (
            <Alert variant="success">{successMessage}</Alert>
          )}
        </div>
      </Card.Body>
    </Card>
  );
}
