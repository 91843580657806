import React, { useEffect, useState } from "react";
import Loader from "../../../../Loader";
import Spacer from "../../../../Spacer";
import Container from "react-bootstrap/Container";
import Card from "react-bootstrap/Card";
import Pagination from "react-bootstrap/Pagination";
import NoResponses from "./NoResponses";

export default function OtherTab({
  dateRange,
  projectsFilter,
  updateRespondentCounter,
}) {
  const [results, setResults] = useState({});
  const [loading, setLoading] = useState(true);
  const [activeStatementIndex, setActiveStatementIndex] = useState(0);

  useEffect(() => {
    async function requestInitialData() {
      setLoading(true);
      const res = await fetch(
        `/api/covid19BuildingUp/calculateStatements?startDate=${dateRange.startDate}&endDate=${dateRange.endDate}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ projectsFilter }),
        }
      );
      const results = await res.json();
      setResults(results);
      updateRespondentCounter(results.total);
      setLoading(false);
    }
    requestInitialData();
  }, [updateRespondentCounter, dateRange, projectsFilter]);

  if (loading || !results) return <Loader />;

  if (results.total === 0) {
    return (
      <NoResponses
        text="No personal statements have been submitted."
        height={400}
      />
    );
  }

  // Safetfy check in case results change to a number too low for the active index
  if (activeStatementIndex > results.total - 1) {
    setActiveStatementIndex(0);
    return <Loader />;
  }

  const statements = results.statements;
  let items = [];

  statements.forEach((statement, index) => {
    items.push(
      <Pagination.Item
        key={index}
        active={index === activeStatementIndex}
        onClick={() => {
          console.log("Clicked");
          setActiveStatementIndex(index);
        }}
      >
        {index + 1}
      </Pagination.Item>
    );
  });

  const statementInfo = statements[activeStatementIndex];

  return (
    <Container className="d-flex flex-column align-items-center">
      <Card style={{ height: 400 }}>
        <Card.Header>
          Statement {activeStatementIndex + 1} of {statements.length}
        </Card.Header>
        <Card.Body className="d-flex justify-content-center align-items-center">
          <blockquote
            className="blockquote mb-0"
            style={{ textAlign: "center" }}
          >
            <p>"{statementInfo.text}"</p>
            <br />
            <p style={{ fontSize: 14 }}>
              {statementInfo.email
                ? `(Follow-up email: ${statementInfo.email})`
                : `(No follow-up email submitted)`}
            </p>
          </blockquote>
        </Card.Body>
      </Card>
      <Spacer height={50} />
      {statements.length > 1 && <Pagination size="lg">{items}</Pagination>}
    </Container>
  );
}
