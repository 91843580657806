import React, { useEffect } from "react";
import Loader from "../../../../Loader";
import { covid19Colors } from "../../../../../constants/colors";
import ScalarScore2 from "../../../../charts/ScalarScore2";
import NoResponses from "./NoResponses";
import Doughnut2 from "../../../../charts/Doughnut2";
import { forms } from "line3_shared/constants/forms";
import HorizontalBar2 from "../../../../charts/HorizontalBar2";
import useGetDataFromQuery from "../../../../../hooks/useGetDataFromQuery";

export default function OverviewTab({ dateRange, updateRespondentCounter }) {
  const { loading, data } = useGetDataFromQuery({
    formId: forms.covid19,
    questionId: "Fc7Uv0IxFZLu",
  });

  useEffect(() => {
    if (data) {
      updateRespondentCounter(data.total);
    }
  }, [updateRespondentCounter, dateRange, data]);

  if (loading) return <Loader />;

  if (data && data.total === 0) {
    return (
      <NoResponses text="No reports have been submitted yet." height={400} />
    );
  }

  const palette = [
    covid19Colors.ppe,
    covid19Colors.screening,
    covid19Colors.monitoring,
    covid19Colors.personalHygiene,
    covid19Colors.cleaningAndSanitation,
    covid19Colors.socialDistancing,
    covid19Colors.other,
  ];

  const respondentsConcernedWithFollowingIssues = {
    formId: forms.covid19,
    buckets: [
      {
        label: "PPE",
        match: { questionId: "UAvcLLQF88RW", answerId: true },
      },
      {
        label: "Screening",
        match: { questionId: "p9zJkGaoS7UV", answerId: true },
      },
      {
        label: "Monitoring",
        match: { questionId: "jmuJmg4wcmYE", answerId: true },
      },
      {
        label: "Personal Hygiene",
        match: { questionId: "s1sciewot623", answerId: true },
      },
      {
        label: "Cleaning and Sanitation",
        match: { questionId: "UmQjxBsYKSH8", answerId: true },
      },
      {
        label: "Social Distancing",
        match: { questionId: "X1jRllrwsGyX", answerId: true },
      },
      {
        label: "Other",
        match: { questionId: "Y03YGU2ieqvE", answerId: true },
      },
    ],
  };

  const howFrequentlyHasThisOccurred = {
    formId: forms.covid19,
    buckets: [
      {
        label: "Just once",
        match: { questionId: "S8nCYgVo7zjg", answerId: "EfEQGDn4XB1G" },
      },
      {
        label: "Twice",
        match: { questionId: "S8nCYgVo7zjg", answerId: "FLTKdDqJjHjv" },
      },
      {
        label: "A few times",
        match: { questionId: "S8nCYgVo7zjg", answerId: "JLg8GiMfJcPg" },
      },
      {
        match: { questionId: "cAL6evbL57cb", answerId: "oSMtDeNzb06H" },
        label: "Once a day",
      },
      {
        match: { questionId: "cAL6evbL57cb", answerId: "S07Jc1roVLmu" },
        label: "Several times a day",
      },
      {
        match: { questionId: "cAL6evbL57cb", answerId: "ptqbIHL0SxsL" },
        label: "Once a week",
      },
      {
        match: { questionId: "cAL6evbL57cb", answerId: "MoXlqOVKLpqj" },
        label: "Several times a week",
      },
      {
        match: { questionId: "cAL6evbL57cb", answerId: "wo0RR38hA6Lk" },
        label: "Once a month",
      },
      {
        match: { questionId: "cAL6evbL57cb", answerId: "MYPPb733J9r7" },
        label: "Several times a month",
      },
    ],
  };

  return (
    <div>
      <HorizontalBar2
        title="Respondents concerned with the following issues"
        palette={palette}
        query={respondentsConcernedWithFollowingIssues}
      />
      <div className="d-flex flex-row justify-content-end mt-5">
        <Doughnut2
          title="Frequency"
          tooltip="How frequently has this occurred?"
          query={howFrequentlyHasThisOccurred}
        />
        <ScalarScore2
          title="Intensity"
          tooltip="How serious are these safety concerns, from your perspective? (1 to 10)"
          query={{
            formId: forms.covid19,
            questionId: "Fc7Uv0IxFZLu",
          }}
        />
        <Doughnut2
          title="Time Period"
          tooltip="This occurred during the last..."
          query={{
            formId: forms.covid19,
            questionId: "a97vtHRcmw3s",
          }}
        />
        <Doughnut2
          title="Repeat"
          tooltip="Is this the first time you are reporting this issue?"
          query={{
            formId: forms.covid19,
            questionId: "NmZGwI9Copl0",
          }}
        />
      </div>
    </div>
  );
}
