import React, { useState } from "react";
import Tab from "react-bootstrap/Tab";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import TabContainer from "../../../../TabContainer";
import OverviewTab from "./OverviewTab";
import PpeTab from "./PpeTab";
import ScreeningTab from "./ScreeningTab";
import MonitoringTab from "./MonitoringTab";
import PersonalHygieneTab from "./PersonalHygieneTab";
import CleaningAndSanitationTab from "./CleaningAndSanitationTab";
import SocialDistancingTab from "./SocialDistancingTab";
import OtherTab from "./OtherTab";
import StatementsTab from "./StatementsTab";
import Spacer from "../../../../Spacer";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import DateRangeButtonAndModal from "../../../../DatePickerButtonAndModal";
import RespondentCounter from "../RespondentCounter";

const tabs = [
  {
    name: "Overview",
    eventKey: "overview",
    Component: OverviewTab,
  },
  {
    name: "PPE",
    eventKey: "ppe",
    Component: PpeTab,
  },
  {
    name: "Screening",
    eventKey: "screening",
    Component: ScreeningTab,
  },
  {
    name: "Monitoring",
    eventKey: "monitoring",
    Component: MonitoringTab,
  },
  {
    name: "Personal Hygiene",
    eventKey: "personalHygiene",
    Component: PersonalHygieneTab,
  },
  {
    name: "Cleaning and Sanitation",
    eventKey: "cleaningAndSanitation",
    Component: CleaningAndSanitationTab,
  },
  {
    name: "Social Distancing",
    eventKey: "socialDistancing",
    Component: SocialDistancingTab,
  },
  {
    name: "Other",
    eventKey: "other",
    Component: OtherTab,
  },
  {
    name: "Statements",
    eventKey: "statements",
    Component: StatementsTab,
  },
];

const allDatesRange = {
  startDate: "1960-01-01",
  endDate: "2100-01-01",
};

export default function Dashboard() {
  const [respondentCount, setRespondentCount] = useState(0);
  const [confirmedDateRange, setConfirmedDateRange] = useState(allDatesRange);

  return (
    <TabContainer defaultActiveKey="overview">
      <Row className="px-5">
        <Col sm={3}>
          <Nav className="flex-column">
            {tabs.map((tab) => {
              return (
                <Nav.Item key={tab.eventKey}>
                  <Nav.Link eventKey={tab.eventKey}>{tab.name}</Nav.Link>
                </Nav.Item>
              );
            })}
          </Nav>
          <Spacer height={15} />
          <RespondentCounter respondentCount={respondentCount} />
          <Spacer height={20} />
          <DateRangeButtonAndModal
            setConfirmedDateRange={setConfirmedDateRange}
          />
        </Col>
        <Col sm={9} lg={6}>
          <Tab.Content>
            {tabs.map((tab) => {
              const { Component, eventKey } = tab;
              return (
                <Tab.Pane
                  key={eventKey}
                  eventKey={eventKey}
                  mountOnEnter
                  unmountOnExit
                >
                  <Component
                    dateRange={confirmedDateRange}
                    updateRespondentCounter={(newCount) => {
                      setRespondentCount(newCount);
                    }}
                  />
                </Tab.Pane>
              );
            })}
          </Tab.Content>
        </Col>
      </Row>
    </TabContainer>
  );
}
