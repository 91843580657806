import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { Card, Spinner } from "react-bootstrap";

export default function Organizations() {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState([]);
  const [organizations, setOrganizations] = useState([]);

  useEffect(() => {
    setLoading(true);
    fetch("/api/organizations").then((response) => {
      if (response.ok) {
        response.json().then((json) => {
          setOrganizations(json.organizations);
        });
      } else {
        if (response.status === 401) {
          history.push("/app/login");
        } else {
          response.json().then((json) => {
            setErrors(["Something went wrong."]);
          });
        }
      }
      setLoading(false);
    });
  }, [history]);

  if (loading) return <Spinner />;

  if (organizations.length === 0) {
    return <p>No organizations found</p>;
  }

  return organizations.map((org) => {
    return (
      <Card key={org.id}>
        <Link to={`/app/org/${org.id}`}>
          <Card.Header>{org.name}</Card.Header>
        </Link>
        {errors.map((err) => (
          <p key={err}>{err}</p>
        ))}
      </Card>
    );
  });
}
