import React, { useEffect, useState } from "react";
import Loader from "../../../../Loader";
import HorizontalBar from "../../../../charts/HorizontalBar";
import { covid19Colors } from "../../../../../constants/colors";
import ScalarScore from "../../../../charts/ScalarScore";
import NoResponses from "./NoResponses";
import Doughnut from "../../../../charts/Doughnut";

const typeLabels = [
  "Employees",
  "Visitors (including clients and contractors)",
  "Other",
];

const issueLabels = [
  "People are not maintaining a 2-metre distance from each other",
  "The design of the workplace doesn't allow for social distancing (including desk spaces and gathering areas)",
  "There are too many people in the workplace to maintain social distancing",
  "There's not enough training on social distancing protocols",
  "The social distancing protocols are not safe enough",
  "Other",
];

export default function SocialDistancingTab({
  dateRange,
  updateRespondentCounter,
}) {
  const [results, setResults] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function requestInitialData() {
      setLoading(true);
      const res = await fetch(
        `/api/covid19/calculateSocialDistancing?startDate=${dateRange.startDate}&endDate=${dateRange.endDate}`
      );
      const results = await res.json();
      setResults(results);
      updateRespondentCounter(results.total);
      setLoading(false);
    }
    requestInitialData();
  }, [updateRespondentCounter, dateRange.startDate, dateRange.endDate]);

  if (loading || !results) return <Loader />;

  if (results.total === 0) {
    return (
      <NoResponses
        text="No Social Distancing concerns have been reported."
        height={400}
      />
    );
  }

  const typeData = typeLabels.map(
    (label) => results.socialDistancingType[label]
  );
  const issueData = issueLabels.map(
    (label) => results.socialDistancingIssue[label]
  );

  return (
    <div>
      <HorizontalBar
        data={typeData}
        labels={typeLabels}
        title="Are these social distancing concerns about..."
        maxValue={results.total}
        palette={covid19Colors.socialDistancing}
      />
      <HorizontalBar
        data={issueData}
        labels={issueLabels}
        title="What are your concerns?"
        maxValue={results.total}
        palette={covid19Colors.socialDistancing}
      />
      <div className="d-flex flex-row">
        <Doughnut
          data={results.frequency}
          title="Frequency"
          tooltip="How frequently has this occurred?"
        />
        <ScalarScore
          score={results.intensity}
          title="Intensity"
          tooltip="How serious are these safety concerns, from your perspective? (1 to 10)"
        />
        <Doughnut
          data={results.timePeriod}
          title="Time Period"
          tooltip="This occurred during the last..."
        />
        <Doughnut
          data={results.repeat}
          title="Repeat"
          tooltip="Is this the first time you are reporting this issue?"
        />
      </div>
    </div>
  );
}
