import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Button, Form, Col, Alert, Card, Row, Spinner } from "react-bootstrap";

const AddUser = () => {
  const history = useHistory();
  const [errors, setErrors] = useState([]);
  const [successMessage, setSuccessMessage] = useState("");
  const [organizations, setOrganizations] = useState([]);

  useEffect(() => {
    fetch("/api/organizations").then((response) => {
      if (response.ok) {
        response.json().then((json) => {
          setOrganizations(json.organizations);
        });
      } else {
        if (response.status === 401) {
          history.push("/app/login");
        } else {
          response.json().then((json) => {
            setErrors(["Something went wrong."]);
          });
        }
      }
    });
  }, [history]);

  function createUser(event) {
    setErrors([]);
    setSuccessMessage("");

    event.preventDefault();

    const formInputs = event.target.elements;
    const body = {
      email: formInputs.formUserEmail.value,
      password: formInputs.formUserPassword.value,
      passwordConfirmation: formInputs.formUserPasswordConfirmation.value,
      organizationId: formInputs.formUserOrganization.value,
    };

    if (body.password !== body.passwordConfirmation) {
      setErrors(["Passwords did not match"]);
      return;
    }

    fetch("/api/signup", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    })
      .then((response) => {
        if (response.ok) {
          response.json().then((json) => {
            setSuccessMessage(json.message);
          });
        } else {
          if (response.status === 401) {
            history.push("/app/login");
          } else {
            response.json().then((json) => {
              setErrors([json.message]);
            });
          }
        }
      })
      .catch((err) => {
        setErrors(["User creation failed. Please try again."]);
      });
  }

  if (organizations.length === 0) {
    return <Spinner />;
  }

  return (
    <Card>
      <Card.Header>Add a new User</Card.Header>
      <Card.Body>
        <Form onSubmit={createUser}>
          <div className="d-flex flex-column">
            <Form.Group as={Row} controlId="formUserEmail">
              <Form.Label column sm="4">
                User Email
              </Form.Label>
              <Col>
                <Form.Control
                  type="text"
                  placeholder="example@company.com"
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row} controlId="formUserPassword">
              <Form.Label column sm="4">
                User Password
              </Form.Label>
              <Col>
                <Form.Control type="text" placeholder="testPassword123" />
              </Col>
            </Form.Group>

            <Form.Group as={Row} controlId="formUserPasswordConfirmation">
              <Form.Label column sm="4">
                Confirm Password
              </Form.Label>
              <Col>
                <Form.Control type="text" placeholder="testPassword123" />
              </Col>
            </Form.Group>

            <Form.Group as={Row} controlId="formUserOrganization">
              <Form.Label column sm="4">
                User Organization
              </Form.Label>
              <Col>
                <Form.Control as="select">
                  {organizations.map((org) => {
                    return <option key={org.id} value={org.id}>{org.name}</option>;
                  })}
                </Form.Control>
              </Col>
            </Form.Group>

            <Button type="submit">Create User</Button>
          </div>
        </Form>
        <div className="mt-3">
          {errors.length > 0 &&
            errors.map((error, index) => {
              return (
                <Alert key={index} variant={"danger"}>
                  {error}
                </Alert>
              );
            })}
          {successMessage.length > 0 && (
            <Alert variant="success">{successMessage}</Alert>
          )}
        </div>
      </Card.Body>
    </Card>
  );
};

export default AddUser;
