import { useEffect, useState } from "react";

export const defaultOrganizationContextData = {
  orgId: undefined,
  orgName: undefined,
  activeFormId: undefined,
  availableFormIds: [],
};

export function useOrganizationContextData() {
  const [orgId, setOrgId] = useState(defaultOrganizationContextData.orgId);
  const [orgName, setOrgName] = useState(defaultOrganizationContextData.orgName)
  const [activeFormId, setActiveFormId] = useState(
    defaultOrganizationContextData.activeFormId
  );
  const [availableFormIds, setAvailableFormIds] = useState(
    defaultOrganizationContextData.availableFormIds
  );

  async function fetchAndSetOrgData() {
    const userRes = await fetch(`/api/users/current`);
    const user = await userRes.json();
    const orgId = user.organizationId;
    setOrgId(orgId);
    const orgRes = await fetch(`/api/organizations/${orgId}`);
    const org = await orgRes.json();
    setOrgName(org.name);
    setAvailableFormIds(org.formIds);
    setActiveFormId(org.formIds[0]);
  }

  useEffect(() => {
    fetchAndSetOrgData();
  }, []);

  return {
    orgId,
    orgName,
    activeFormId,
    setActiveFormId,
    availableFormIds,
  };
}
