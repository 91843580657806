import React, { useEffect } from "react";
import HorizontalBar2 from "../../../../charts/HorizontalBar2";
import IdentityTargetedChart, {
  IDENTITY_BUCKETS_TYPE,
} from "./charts/IdentityTargetedChart";
import IncidentTargetChart from "./charts/IncidentTargetChart";
import IncidentTransgressorChart from "./charts/IncidentTransgressorChart";
import BottomDoughnuts from "./charts/BottomDoughnuts";
import { forms } from "line3_shared/constants/forms";
import { concernsColorPalette } from "../../../../../constants/colors";
import Loader from "../../../../Loader";
import useGetDataFromQuery from "../../../../../hooks/useGetDataFromQuery";

export default function ExclusionFavouritismTab({
  dateRange,
  updateRespondentCounter,
}) {
  const exclusionFavoritismFilter = {
    or: [
      { questionId: "6Scklup520Lb", answerId: true }, // Avoiding or excluding certain people
      { questionId: "0aiSGGNFJ9mA", answerId: true }, // Attention-seeking behaviour
      { questionId: "T4TywmqAb8e8", answerId: true }, // Favoritism for certain people
    ],
  };

  const { loading, data } = useGetDataFromQuery({
    formId: forms.concerns,
    questionId: "hxevrGkgspp5",
    filter: exclusionFavoritismFilter,
  });

  useEffect(() => {
    if (data) {
      updateRespondentCounter(data.total);
    }
  }, [updateRespondentCounter, dateRange, data]);

  if (loading) return <Loader />;

  const behavioursReported = {
    formId: forms.concerns,
    buckets: [
      {
        label: "Avoiding or excluding certain people",
        match: { questionId: "6Scklup520Lb", answerId: true },
      },
      {
        label: "Attention-seeking behaviour",
        match: { questionId: "0aiSGGNFJ9mA", answerId: true },
      },
      {
        label: "Favoritism for certain people",
        match: { questionId: "T4TywmqAb8e8", answerId: true },
      },
    ],
  };

  return (
    <div>
      <HorizontalBar2
        title="Behaviours Reported"
        palette={concernsColorPalette}
        query={behavioursReported}
      />
      <IdentityTargetedChart
        filter={exclusionFavoritismFilter}
        identityBucketsType={IDENTITY_BUCKETS_TYPE.GENERAL}
      />
      <IncidentTargetChart filter={exclusionFavoritismFilter} />
      <IncidentTransgressorChart filter={exclusionFavoritismFilter} />
      <BottomDoughnuts filter={exclusionFavoritismFilter} />
    </div>
  );
}
