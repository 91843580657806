import React, { useEffect, useState } from "react";
import Loader from "../../../../Loader";
import HorizontalBar from "../../../../charts/HorizontalBar";
import { covid19Colors } from "../../../../../constants/colors";
import ScalarScore from "../../../../charts/ScalarScore";
import NoResponses from "./NoResponses";
import Doughnut from "../../../../charts/Doughnut";

const typeLabels = [
  "Employees",
  "Visitors (including clients and contractors)",
  "Other",
];

const issueLabels = [
  "Personal hygiene supplies (like hand sanitizer) are not available",
  "There aren't enough personal hygiene supplies",
  "Personal hygiene protocols are not being followed (like regular handwashing and covering mouth when coughing)",
  "There isn't enough training on how/when to do it",
  "The personal hygiene supplies and protocols are not safe enough",
  "Other",
];

export default function PersonalHygieneTab({
  dateRange,
  updateRespondentCounter,
}) {
  const [results, setResults] = useState({});
  const [loading, setLoading] = useState(true);


  useEffect(() => {
    async function requestInitialData() {
      setLoading(true);
      const res = await fetch(
        `/api/covid19/calculatePersonalHygiene?startDate=${dateRange.startDate}&endDate=${dateRange.endDate}`
      );
      const results = await res.json();
      setResults(results);
      updateRespondentCounter(results.total);
      setLoading(false);
    }
    requestInitialData();
  }, [updateRespondentCounter, dateRange.startDate, dateRange.endDate]);

  if (loading || !results) return <Loader />;

  if (results.total === 0) {
    return (
      <NoResponses
        text="No Personal Hygiene concerns have been reported."
        height={400}
      />
    );
  }

  const typeData = typeLabels.map(
    (label) => results.personalHygieneType[label]
  );
  const issueData = issueLabels.map(
    (label) => results.personalHygieneIssue[label]
  );

  return (
    <div>
      <HorizontalBar
        data={typeData}
        labels={typeLabels}
        title="Are these concerns about the personal hygiene of..."
        maxValue={results.total}
        palette={covid19Colors.personalHygiene}
      />
      <HorizontalBar
        data={issueData}
        labels={issueLabels}
        title="What kind of personal hygiene issues?"
        maxValue={results.total}
        palette={covid19Colors.personalHygiene}
      />
      <div className="d-flex flex-row">
        <Doughnut
          data={results.frequency}
          title="Frequency"
          tooltip="How frequently has this occurred?"
        />
        <ScalarScore
          score={results.intensity}
          title="Intensity"
          tooltip="How serious are these safety concerns, from your perspective? (1 to 10)"
        />
        <Doughnut
          data={results.timePeriod}
          title="Time Period"
          tooltip="This occurred during the last..."
        />
        <Doughnut
          data={results.repeat}
          title="Repeat"
          tooltip="Is this the first time you are reporting this issue?"
        />
      </div>
    </div>
  );
}
