import React, { useEffect } from "react";
import HorizontalBar2 from "../../../../charts/HorizontalBar2";
import { concernsColorPalette } from "../../../../../constants/colors";
import IdentityTargetedChart, {
  IDENTITY_BUCKETS_TYPE,
} from "./charts/IdentityTargetedChart";
import IncidentTargetChart from "./charts/IncidentTargetChart";
import IncidentTransgressorChart from "./charts/IncidentTransgressorChart";
import BottomDoughnuts from "./charts/BottomDoughnuts";
import useGetDataFromQuery from "../../../../../hooks/useGetDataFromQuery";
import { forms } from "line3_shared/constants/forms";
import Loader from "../../../../Loader";

export default function PhysicalContactTab({
  dateRange,
  updateRespondentCounter,
}) {
  const physicalContactFilter = {
    or: [
      {
        questionId: "vlipHxg2Vhal",
        answerId: true,
      },
    ],
  };

  const { loading, data } = useGetDataFromQuery({
    formId: forms.concerns,
    questionId: "vlipHxg2Vhal",
    filter: physicalContactFilter,
  });

  useEffect(() => {
    if (data) {
      updateRespondentCounter(data.total);
    }
  }, [updateRespondentCounter, dateRange, data]);

  if (loading) return <Loader />;
  return (
    <div>
      <HorizontalBar2
        title="Which body part was used to do the touching?"
        palette={concernsColorPalette}
        query={{
          formId: forms.concerns,
          questionId: "4StgfVwvQOhg",
          filter: physicalContactFilter,
        }}
      />
      <HorizontalBar2
        title="What body part or area was touched?"
        palette={concernsColorPalette}
        query={{
          formId: forms.concerns,
          questionId: "A1tc5loXHit1",
          filter: physicalContactFilter,
        }}
      />
      <HorizontalBar2
        title="What kind of a touch?"
        palette={concernsColorPalette}
        query={{
          formId: forms.concerns,
          questionId: "OP2ZmRcSmzyf",
          filter: physicalContactFilter,
        }}
      />
      <IdentityTargetedChart
        filter={physicalContactFilter}
        identityBucketsType={IDENTITY_BUCKETS_TYPE.GENERAL}
      />
      <IncidentTargetChart filter={physicalContactFilter} />
      <IncidentTransgressorChart filter={physicalContactFilter} />
      <BottomDoughnuts filter={physicalContactFilter} />
    </div>
  );
}
