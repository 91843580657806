import React, { useEffect } from "react";
import HorizontalBar2 from "../../../../charts/HorizontalBar2";
import IdentityTargetedChart, {
  IDENTITY_BUCKETS_TYPE,
} from "./charts/IdentityTargetedChart";
import IncidentTargetChart from "./charts/IncidentTargetChart";
import IncidentTransgressorChart from "./charts/IncidentTransgressorChart";
import BottomDoughnuts from "./charts/BottomDoughnuts";
import { forms } from "line3_shared/constants/forms";
import { concernsColorPalette } from "../../../../../constants/colors";
import Loader from "../../../../Loader";
import useGetDataFromQuery from "../../../../../hooks/useGetDataFromQuery";

export default function SexualCommentsTab({
  dateRange,
  updateRespondentCounter,
}) {
  const sexualCommentsFilter = {
    or: [
      {
        questionId: "2mi2KfWz8jLP",
        answerId: true,
      },
    ],
  };

  const { loading, data } = useGetDataFromQuery({
    formId: forms.concerns,
    questionId: "hxevrGkgspp5",
    filter: sexualCommentsFilter,
  });

  useEffect(() => {
    if (data) {
      updateRespondentCounter(data.total);
    }
  }, [updateRespondentCounter, dateRange, data]);

  if (loading) return <Loader />;

  const behavioursReported = {
    formId: forms.concerns,
    buckets: [
      {
        label: "Explicit sexual comments",
        match: { questionId: "aDpXyKDc4kY9", answerId: "WkfeitNANotY" },
      },
      {
        label: "About sexual orientation",
        match: { questionId: "aDpXyKDc4kY9", answerId: "yjpXgR6nMrH9" },
      },
      {
        label: "About gender identity",
        match: { questionId: "aDpXyKDc4kY9", answerId: "H9UBWkjBk3Zq" },
      },
      {
        label: "Sexual advances",
        match: { questionId: "aDpXyKDc4kY9", answerId: "RhjRBfIS4k5V" },
      },
      {
        label: "Requests to be in a romantic relationship",
        match: { questionId: "aDpXyKDc4kY9", answerId: "FFd0UJxOaQLP" },
      },
    ],
  };

  return (
    <div>
      <HorizontalBar2
        title="Comment Type"
        palette={concernsColorPalette}
        query={behavioursReported}
      />
      <IdentityTargetedChart
        filter={sexualCommentsFilter}
        identityBucketsType={IDENTITY_BUCKETS_TYPE.COMMENTS}
      />
      <IncidentTargetChart filter={sexualCommentsFilter} />
      <IncidentTransgressorChart filter={sexualCommentsFilter} />
      <BottomDoughnuts
        filter={sexualCommentsFilter}
        includeCommentCharts={true}
      />
    </div>
  );
}
