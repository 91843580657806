import React from "react";
import { forms } from "line3_shared/constants/forms";
import HorizontalBar2 from "../../../../../charts/HorizontalBar2";
import { concernsColorPalette } from "../../../../../../constants/colors";

const commentRelatedBuckets = [
  {
    label: "Ancestry",
    match: { questionId: "X9E9yTDkx9ga", answerId: "kjgomweIvbnC" },
  },
  {
    label: "Race or Culture",
    match: { questionId: "X9E9yTDkx9ga", answerId: "p0ZB4BaHfUqm" },
  },
  {
    label: "Age",
    match: { questionId: "X9E9yTDkx9ga", answerId: "OPeGnOumbWZD" },
  },
  {
    label: "Mental or Physical Health",
    match: { questionId: "X9E9yTDkx9ga", answerId: "E0Vz76dDacnT" },
  },
  {
    label: "Political of Religious Beliefs",
    match: { questionId: "X9E9yTDkx9ga", answerId: "Y8KHeUFV5f05" },
  },
  {
    label: "Gender or Sexuality",
    match: { questionId: "X9E9yTDkx9ga", answerId: "jdSkIR74wrIO" },
  },
  {
    label: "Relationship or Family",
    match: { questionId: "X9E9yTDkx9ga", answerId: "CXbrnHNSQiMC" },
  },
  {
    label: "Do not wish to specify",
    match: { questionId: "X9E9yTDkx9ga", answerId: "PaJNlprpGdfw" },
  },
];

const generalBehaviourBuckets = [
  {
    label: "Ancestry",
    match: { questionId: "CrgiNGVz9R4k", answerId: "kK4VnwLxShZD" },
  },
  {
    label: "Race or Culture",
    match: { questionId: "CrgiNGVz9R4k", answerId: "lDSOUvWzXSsW" },
  },
  {
    label: "Age",
    match: { questionId: "CrgiNGVz9R4k", answerId: "RFIKn5KMbKVW" },
  },
  {
    label: "Mental or Physical Health",
    match: { questionId: "CrgiNGVz9R4k", answerId: "jd1b9hwTinKY" },
  },
  {
    label: "Political of Religious Beliefs",
    match: { questionId: "CrgiNGVz9R4k", answerId: "Z660ceJvJd5Y" },
  },
  {
    label: "Gender or Sexuality",
    match: { questionId: "CrgiNGVz9R4k", answerId: "uljGazt5LBLh" },
  },
  {
    label: "Relationship or Family",
    match: { questionId: "CrgiNGVz9R4k", answerId: "37sLekK1Z542" },
  },
  {
    label: "Do not wish to specify",
    match: { questionId: "CrgiNGVz9R4k", answerId: "UGMwdWCqquVl" },
  },
];

const commentRelatedAndGeneralBehaviourBuckets = [
  {
    label: "General Comments",
    match: {
      or: [
        { questionId: "X9E9yTDkx9ga", answerId: "kjgomweIvbnC" },
        { questionId: "CrgiNGVz9R4k", answerId: "kK4VnwLxShZD" },
      ],
    },
  },
  {
    label: "Race or Culture",
    match: {
      or: [
        { questionId: "X9E9yTDkx9ga", answerId: "p0ZB4BaHfUqm" },
        { questionId: "CrgiNGVz9R4k", answerId: "lDSOUvWzXSsW" },
      ],
    },
  },
  {
    label: "Age",
    match: {
      or: [
        { questionId: "X9E9yTDkx9ga", answerId: "OPeGnOumbWZD" },
        { questionId: "CrgiNGVz9R4k", answerId: "RFIKn5KMbKVW" },
      ],
    },
  },
  {
    label: "Mental or Physical Health",
    match: {
      or: [
        { questionId: "X9E9yTDkx9ga", answerId: "E0Vz76dDacnT" },
        { questionId: "CrgiNGVz9R4k", answerId: "jd1b9hwTinKY" },
      ],
    },
  },
  {
    label: "Political of Religious Beliefs",
    match: {
      or: [
        { questionId: "X9E9yTDkx9ga", answerId: "Y8KHeUFV5f05" },
        { questionId: "CrgiNGVz9R4k", answerId: "Z660ceJvJd5Y" },
      ],
    },
  },
  {
    label: "Gender or Sexuality",
    match: {
      or: [
        { questionId: "X9E9yTDkx9ga", answerId: "jdSkIR74wrIO" },
        { questionId: "CrgiNGVz9R4k", answerId: "uljGazt5LBLh" },
      ],
    },
  },
  {
    label: "Relationship or Family",
    match: {
      or: [
        { questionId: "X9E9yTDkx9ga", answerId: "CXbrnHNSQiMC" },
        { questionId: "CrgiNGVz9R4k", answerId: "37sLekK1Z542" },
      ],
    },
  },
  {
    label: "Do not wish to specify",
    match: {
      or: [
        { questionId: "X9E9yTDkx9ga", answerId: "PaJNlprpGdfw" },
        { questionId: "CrgiNGVz9R4k", answerId: "UGMwdWCqquVl" },
      ],
    },
  },
];

export const IDENTITY_BUCKETS_TYPE = {
  COMMENTS: "comments",
  GENERAL: "general",
  BOTH_COMMENTS_AND_GENERAL: "both_comments_and_general",
};

export default function IdentityTargetedChart({ filter, identityBucketsType }) {
  let buckets;

  switch (identityBucketsType) {
    case IDENTITY_BUCKETS_TYPE.COMMENTS:
      buckets = commentRelatedBuckets;
      break;
    case IDENTITY_BUCKETS_TYPE.GENERAL:
      buckets = generalBehaviourBuckets;
      break;
    case IDENTITY_BUCKETS_TYPE.BOTH_COMMENTS_AND_GENERAL:
      buckets = commentRelatedAndGeneralBehaviourBuckets;
      break;
    default:
      buckets = generalBehaviourBuckets;
      break;
  }

  const identityTargeted = {
    formId: forms.concerns,
    buckets,
    filter,
  };

  return (
    <HorizontalBar2
      title="Behaviour occurred because of the target's..."
      palette={concernsColorPalette}
      query={identityTargeted}
    />
  );
}
