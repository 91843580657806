import { forms } from "line3_shared/constants/forms";
import React, { useContext } from "react";
import OrganizationContext from "../../../../contexts/OrganizationContext";
import Loader from "../../../Loader";
import ConcernsDashboard from "./concerns/Dashboard";
import CoreModuleDashboard from "./coreModule/Dashboard";
import Covid19Dashboard from "./covid19/Dashboard";
import Covid19BuildingUpDashboard from "./covid19BuildingUp/Dashboard";
import ShortenedFormDashboard from "./shortenedForm/Dashboard";

export default function DashboardSelector() {
  const { activeFormId } = useContext(OrganizationContext);

  if (!activeFormId) {
    return <Loader />;
  } else {
    if (activeFormId === forms.covid19) {
      return <Covid19Dashboard />;
    } else if (activeFormId === forms.covid19BuildingUp) {
      return <Covid19BuildingUpDashboard />;
    } else if (activeFormId === forms.coreModule) {
      return <CoreModuleDashboard />;
    } else if (activeFormId === forms.concerns) {
      return <ConcernsDashboard />;
    } else if (activeFormId === forms.shortenedForm) {
      return <ShortenedFormDashboard />;
    }
  }
}
