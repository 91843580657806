import React from "react";
import Container from "react-bootstrap/Container";

export default function NoResponses({ text, height = "auto" }) {
  return (
    <Container
      style={{ height }}
      className="d-flex justify-content-center align-items-center"
    >
      <div>{text}</div>
    </Container>
  );
}
