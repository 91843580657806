import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import { Bar } from "react-chartjs-2";
// import 'chartjs-plugin-labels'

export default function OverviewTab() {
  // const something = {
  //   type: "bar",
  //   data: {
  //     labels: ["January", "February", "March", "April", "May", "June", "July"],
  //     datasets: [
  //       {
  //         label: "My First Dataset",
  //         data: [65, 59, 80, 81, 56, 55, 40],
  //         fill: false,
  //         backgroundColor: [
  //           "rgba(255, 99, 132, 0.2)",
  //           "rgba(255, 159, 64, 0.2)",
  //           "rgba(255, 205, 86, 0.2)",
  //           "rgba(75, 192, 192, 0.2)",
  //           "rgba(54, 162, 235, 0.2)",
  //           "rgba(153, 102, 255, 0.2)",
  //           "rgba(201, 203, 207, 0.2)",
  //         ],
  //         borderColor: [
  //           "rgb(255, 99, 132)",
  //           "rgb(255, 159, 64)",
  //           "rgb(255, 205, 86)",
  //           "rgb(75, 192, 192)",
  //           "rgb(54, 162, 235)",
  //           "rgb(153, 102, 255)",
  //           "rgb(201, 203, 207)",
  //         ],
  //         borderWidth: 1,
  //       },
  //     ],
  //   },
  //   options: {
  //     scales: {
  //       yAxes: [
  //         {
  //           ticks: {
  //             beginAtZero: true,
  //           },
  //         },
  //       ],
  //     },
  //   },
  // };

  const chartData = {
    labels: ["Discrimination", "Harassment", "Violence", "Fraud"],
    datasets: [
      {
        label: "",
        data: [24, 44, 16, 8],
        fill: false,
        backgroundColor: [
          "rgba(255, 99, 132, 0.2)",
          "rgba(255, 159, 64, 0.2)",
          "rgba(255, 205, 86, 0.2)",
          "rgba(75, 192, 192, 0.2)",
        ],
        borderWidth: 1,
      },
    ],

    // These labels appear in the legend and in the tooltips when hovering different arcs
    // labels: ['Yes', 'No'],
  };

  const chartOptions = {
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
            suggestedMax: 100,
          },
        },
      ],
    },
    legend: {
      display: false,
    },
    title: {
      display: true,
      text: "Prevalence of Reported HR Issues",
    },
    plugins: {
      labels: {
        // render 'label', 'value', 'percentage', 'image' or custom function, default is 'percentage'
        render: function (args) {
          return args.value + "%";
        },
      },
    },
    label: {
      display: true,
    },
    // maintainAspectRatio: false,
    // tooltips: {
    //   callbacks: {
    //     label: function(tooltipItem, data) {
    //       var dataset = data.datasets[tooltipItem.datasetIndex];
    //       var meta = dataset._meta[Object.keys(dataset._meta)[0]];
    //       var total = meta.total;
    //       var currentValue = dataset.data[tooltipItem.index];
    //       var percentage = parseFloat((currentValue/total*100).toFixed(1));
    //       return percentage + '%';
    //     },
    //     title: function(tooltipItem, data) {
    //       return data.labels[tooltipItem[0].index];
    //     }
    //   }
    // }
  };

  return (
    <Container>
      <Row>
        <Col>
          <Bar data={chartData} options={chartOptions} />
        </Col>
      </Row>
    </Container>
  );
}
