export default function sortFunctionTreatingArraysAsStrings(a, b) {
  if (a.join('') < b.join('')) {
    return -1;
  }

  if (a.join('') > b.join('')) {
    return 1;
  }

  return 0;
}