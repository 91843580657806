import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import { Doughnut } from "react-chartjs-2";
import "chartjs-plugin-labels";

export default function HarassmentTab() {
  const chartData = {
    datasets: [
      {
        data: [22, 28],
        backgroundColor: ["rgba(255, 99, 132, 0.4)", "rgba(75, 192, 192, 0.4)"],
      },
    ],

    // These labels appear in the legend and in the tooltips when hovering different arcs
    labels: ["Yes", "No"],
  };

  const chartOptions = {
    title: {
      display: true,
      text: "Respondents Reporting Harassment",
    },
    plugins: {
      labels: {
        // render 'label', 'value', 'percentage', 'image' or custom function, default is 'percentage'
        render: "value",
      },
    },
    tooltips: {
      callbacks: {
        label: function (tooltipItem, data) {
          var dataset = data.datasets[tooltipItem.datasetIndex];
          var meta = dataset._meta[Object.keys(dataset._meta)[0]];
          var total = meta.total;
          var currentValue = dataset.data[tooltipItem.index];
          var percentage = parseFloat(
            ((currentValue / total) * 100).toFixed(1)
          );
          return percentage + "%";
        },
        title: function (tooltipItem, data) {
          return data.labels[tooltipItem[0].index];
        },
      },
    },
  };

  return (
    <Container>
      <Row>
        <Col>
          <Doughnut data={chartData} options={chartOptions} />
        </Col>
      </Row>
    </Container>
  );
}
