import { forms } from "line3_shared/constants/forms";
import React, { useEffect } from "react";
import { concernsColorPalette } from "../../../../../constants/colors";
import useGetDataFromQuery from "../../../../../hooks/useGetDataFromQuery";
import Doughnut2 from "../../../../charts/Doughnut2";
import HorizontalBar2 from "../../../../charts/HorizontalBar2";
import Loader from "../../../../Loader";
import Spacer from "../../../../Spacer";

export default function OverviewTab({ updateRespondentCounter }) {
  const { loading, data } = useGetDataFromQuery({
    formId: forms.shortenedForm,
    questionId: "81iLlOKLMEr2",
  });

  useEffect(() => {
    if (data) {
      updateRespondentCounter(data.total);
    }
  }, [updateRespondentCounter, data]);

  if (loading) return <Loader />;

  // TODO: remove "None of the Above" from the top one
  return (
    <div>
      <HorizontalBar2
        title="Topic of Concerns and Feedback"
        palette={concernsColorPalette}
        query={{
          formId: forms.shortenedForm,
          questionId: "81iLlOKLMEr2",
          excludeAnswerIds: ["CN823tWqgQSX"], // Exclude "None of the above"
        }}
      />
      <Spacer height={40} />
      <HorizontalBar2
        title="Transgressor"
        palette={concernsColorPalette}
        query={{
          formId: forms.shortenedForm,
          questionId: "7kKrhvt40hRq",
        }}
      />
      <Spacer height={40} />
      <div className="d-flex flex-row justify-content-between">
        <Doughnut2
          title={"One-time or Ongoing Issue"}
          query={{ formId: forms.shortenedForm, questionId: "PpHsLIrUFKq3" }}
        />
        <Doughnut2
          title={"Previously Reported to Management"}
          query={{ formId: forms.shortenedForm, questionId: "wIP0ordQCY7b" }}
        />
      </div>
    </div>
  );
}
