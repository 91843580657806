import React, { useEffect } from "react";
import HorizontalBar2 from "../../../../charts/HorizontalBar2";
import IdentityTargetedChart, {
  IDENTITY_BUCKETS_TYPE,
} from "./charts/IdentityTargetedChart";
import IncidentTargetChart from "./charts/IncidentTargetChart";
import IncidentTransgressorChart from "./charts/IncidentTransgressorChart";
import BottomDoughnuts from "./charts/BottomDoughnuts";
import { forms } from "line3_shared/constants/forms";
import { concernsColorPalette } from "../../../../../constants/colors";
import Loader from "../../../../Loader";
import useGetDataFromQuery from "../../../../../hooks/useGetDataFromQuery";

export default function GeneralCommentsTab({
  dateRange,
  updateRespondentCounter,
}) {
  const generalCommentsFilter = {
    or: [
      { questionId: "spNn7xtWmtbz", answerId: "R5Gmf2oU2sZk" },
      { questionId: "spNn7xtWmtbz", answerId: "DbhUolHzFjP2" },
      { questionId: "spNn7xtWmtbz", answerId: "MzkQToSTY6rj" },
      { questionId: "spNn7xtWmtbz", answerId: "MB6yadcMi4mn" },
      { questionId: "spNn7xtWmtbz", answerId: "Z2K9rotOmQEc" },
      { questionId: "spNn7xtWmtbz", answerId: "bL747UzeVpRk" },
    ],
  };

  const { loading, data } = useGetDataFromQuery({
    formId: forms.concerns,
    questionId: "hxevrGkgspp5",
    filter: generalCommentsFilter,
  });

  useEffect(() => {
    if (data) {
      updateRespondentCounter(data.total);
    }
  }, [updateRespondentCounter, dateRange, data]);

  if (loading) return <Loader />;

  const behavioursReported = {
    formId: forms.concerns,
    buckets: [
      {
        label: "Slurs or derogatory language",
        match: { questionId: "spNn7xtWmtbz", answerId: "R5Gmf2oU2sZk" },
      },
      {
        label: "Teasing or insults",
        match: { questionId: "spNn7xtWmtbz", answerId: "DbhUolHzFjP2" },
      },
      {
        label: "Gossip or spreading rumours",
        match: { questionId: "spNn7xtWmtbz", answerId: "MzkQToSTY6rj" },
      },
      {
        label: "Revealing private or personal information to others",
        match: { questionId: "spNn7xtWmtbz", answerId: "MB6yadcMi4mn" },
      },
      {
        label: "Comments that indicate a bias against certain people",
        match: { questionId: "spNn7xtWmtbz", answerId: "Z2K9rotOmQEc" },
      },
      {
        label: "Other",
        match: { questionId: "spNn7xtWmtbz", answerId: "bL747UzeVpRk" },
      },
    ],
  };

  return (
    <div>
      <HorizontalBar2
        title="Comment Type"
        palette={concernsColorPalette}
        query={behavioursReported}
      />
      <IdentityTargetedChart
        filter={generalCommentsFilter}
        identityBucketsType={IDENTITY_BUCKETS_TYPE.COMMENTS}
      />
      <IncidentTargetChart filter={generalCommentsFilter} />
      <IncidentTransgressorChart filter={generalCommentsFilter} />
      <BottomDoughnuts
        filter={generalCommentsFilter}
        includeCommentCharts={true}
      />
    </div>
  );
}
