import React from "react";
import { HorizontalBar as ChartJsHorizontalBar } from "react-chartjs-2";
import { QueryType } from "../../../../line3_shared/types/QueryType";
import splitStringToArrayByLength from "../../helpers/splitStringToArrayByLength";
import useGetDataFromQuery from "../../hooks/useGetDataFromQuery";
import Loader from "../Loader";

type Props = {
  title: string;
  palette: Array<string>;
  query: QueryType;
};

export default function HorizontalBar({ title, palette, query }: Props) {
  const { loading, data } = useGetDataFromQuery(query);

  if (loading || !data) return <Loader />;

  // this isn't actually the bar thickness, just a rough number used to get the height somewhat consistent
  const BAR_THICKNESS_MULTIPLIER = 12;
  // this is an approximation as well
  const TITLE_HEIGHT_ROUGHLY = 35;
  const WRAP_TEXT_CHARACTERS = 36;
  const LABEL_WIDTH = 225;

  const labels = data && data.labels.map((label) => label.label);
  const values = data && data.labels.map((label) => label.count);

  // if labels wrap, we need to know how many "rows" they are
  const maxNumberOfLabelRows = labels.reduce((acc, label) => {
    return Math.max(
      acc,
      splitStringToArrayByLength(label, WRAP_TEXT_CHARACTERS).length
    );
  }, 1);

  const chartData = {
    labels,
    datasets: [
      {
        data: values,
        fill: false,
        backgroundColor: palette,
        borderWidth: 1,
      },
    ],
  };

  const chartOptions = {
    scales: {
      xAxes: [
        {
          ticks: {
            beginAtZero: true,
            suggestedMax: Math.max(data.total, 3),
            precision: 0,
          },
        },
      ],
      yAxes: [
        {
          ticks: {
            callback: (value) => {
              return splitStringToArrayByLength(value, WRAP_TEXT_CHARACTERS);
            },
          },
          afterFit: (me) => {
            me.width = LABEL_WIDTH;
          },
        },
      ],
    },
    legend: {
      display: false,
    },
    title: {
      display: true,
      text: title,
    },
  };

  return (
    <ChartJsHorizontalBar
      data={chartData}
      options={chartOptions}
      height={
        BAR_THICKNESS_MULTIPLIER * maxNumberOfLabelRows * data.labels.length +
        TITLE_HEIGHT_ROUGHLY
      }
    />
  );
}
