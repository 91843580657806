import React from "react";
import { colors } from "../../constants/colors";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import useGetDataFromQuery from "../../hooks/useGetDataFromQuery";
import Loader from "../Loader";

export default function ScalarScore({ title, tooltip, query }) {
  const { loading, data } = useGetDataFromQuery(query);

  if (loading || !data) return <Loader />;

  const score = data.averageValue;

  function scoreColor() {
    if (score < 10.0 / 6) {
      return colors.chartRedGreen6;
    } else if (score < (10.0 / 6) * 2) {
      return colors.chartRedGreen5;
    } else if (score < (10.0 / 6) * 3) {
      return colors.chartRedGreen4;
    } else if (score < (10.0 / 6) * 4) {
      return colors.chartRedGreen3;
    } else if (score < (10.0 / 6) * 5) {
      return colors.chartRedGreen2;
    } else {
      return colors.chartRedGreen1;
    }
  }

  function renderTooltip(props) {
    return (
      <Tooltip id="scalar-tooltip" {...props}>
        {tooltip}
      </Tooltip>
    );
  }

  return (
    <div className="d-flex flex-column align-items-center mx-2 px-3">
      <OverlayTrigger
        placement="right"
        delay={{ show: 200, hide: 0 }}
        overlay={renderTooltip}
      >
        <div
          className="d-flex justify-content-center align-items-center"
          style={{
            border: "solid 5px",
            borderColor: scoreColor(),
            borderRadius: "50%",
            color: scoreColor(),
            height: 60,
            width: 60,
          }}
        >
          <h4 className="mb-0">{Math.round(score * 10) / 10}</h4>
        </div>
      </OverlayTrigger>
      <p>{title}</p>
    </div>
  );
}
